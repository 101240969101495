<template>
  <div class="events-countdown-item-info">
    <b-row>
      <b-col>
        <div class="single-event-contents">
          <h3 class="event-title">{{updateRecord.title}}</h3>
          <div class="event-desc">
            <p>
              {{updateRecord.content}}
            </p>
          </div>
        </div>
      </b-col>

      <!-- <b-col sm="4">
        <div class="event-speakers">
          <h3 class="event-title">
            Event Speakers
          </h3>
          <ul class="event-speaker-list">
            <li>
              <a
                href="https://demo.themeum.com/wordpress/languageschool/speaker/ricky-martin/"
                ><img
                  src="https://demo.themeum.com/wordpress/languageschool/wp-content/uploads/2019/05/speaker1.jpg"
              /></a>
              <a
                class="speaker-name d-block"
                href="https://demo.themeum.com/wordpress/languageschool/speaker/ricky-martin/"
                >Bradford Esteve</a
              >
              <p class="designation">CEO, ExampleSolution</p>
            </li>
          </ul>
        </div>
      </b-col> -->
    </b-row>
  </div>
</template>
<script>
import Bus from "@/utils/EventBus";
export default {
  name: "UpdateDetails",
  props:{
      updateRecord:{
          tyep:Object,
          require:true
      }
  },
  methods: {
    clickDetail(data) {
      Bus.$emit("initUpdateRecordInfo", data);
    },
  },
};
</script>
<style scoped>
p.title-small,
.events-countdown-item #comingsoon-countdown .countdown-period,
.countdown-event-info-left ul li p {
  opacity: 0.7;
  font-family: "NunitoSans-Regular";
  font-size: 14px;
  color: #212127;
  letter-spacing: -0.33px;
  line-height: 26px;
  margin-bottom: 5px;
  font-weight: 400;
}
.events-countdown-item-info {
  padding-bottom: 50px;
}
.event-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.event-desc {
  color: #1d252d;
  opacity: 0.6;
  font-size: 16px;
  line-height: 24px;
}
.price-box {
  font-size: 16px;
  color: #1d252d;
  margin-bottom: 30px;
}
.event-speaker-list img {
  border-radius: 50%;
  max-width: 120px;
}
.event-speaker-list .speaker-name {
  font-weight: 600;
  font-size: 16px;
  color: #1d252d;
  margin-top: 15px;
}
.single-event-contents .btn-primary {
  background-color: var(--languageschool-major-color);
  border-color: var(--languageschool-major-color);
  border-radius: 0;
  margin-bottom: 30px;
}
.single-event-contents .btn-primary:hover {
  background-color: var(--languageschool-text-color);
  border-color: var(--languageschool-text-color);
}
.event-speaker-list li {
  margin-bottom: 20px;
  width: 50%;
  float: left;
  min-height: 200px;
}
@media (max-width: 767px) {
  .event-speakers {
    margin-top: 20px;
  }
  .event-single-top-info {
    padding: 30px;
  }
}
</style>
