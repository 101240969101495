<template>
  <div class="treeview">
    <ul class="nav navbar-stacked" v-if="classIfyList">
      <li v-for="(item,index) in classIfyList" v-bind:key="index" :class="classifyId== item.articleClassifyId? 'active':null">
        <a href="javascript:void(0);" @click="handleClick(item.articleClassifyId)">{{item.label}}<span class="pull-right" v-if="item.count > 0">({{item.count}})</span></a>
      </li>
    </ul>
  </div>
</template>
<script>
import EventBus from "@/utils/EventBus";
export default {
  name: "TreeView",
  props:{
    classIfyList:{
      type:Array,
      require:true,
    }
  },
  data(){
    return {
      classifyId:null
    }
  },
  methods:{
    handleClick(classifyId){
      this.classifyId = classifyId;
      EventBus.$emit("initArticleData", null,classifyId,null);
    },
    
  },
  mounted(){
  }
};
</script>
<style scoped src="../../css/child/TreeView.css"></style>
