import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home';
// import MainPage from '@/components/Main';
import Book from '@/components/Book';
import Program from '@/components/Program';
import About from '@/components/About';
import Game from '@/components/Game';
import Task from '@/components/Task';
import Personal from '@/components/Personal';
import UpdateRecord from '@/components/UpdateRecord';
import Message from '@/components/Message';
import Memory from '@/components/Memory';
import Travel from '@/components/Travel';
import MLayout from "@/components/layout/MLayout";
import Login from '@/components/Login';
import Register from '@/components/Register';
import ForgotPassword from '@/components/ForgotPassword';
import Details from '@/components/Details';
import DiaryDetail from '@/components/DiaryDetail';

Vue.use(Router)
export default new Router({
    mode: 'history',
    routes:[
        {
            path:'/login',
            component:Login,
        },
        {
            path:'/register',
            component:Register,
        },
        {
            path:'/forgot/password',
            component:ForgotPassword,
        },
        {
            path:'/task',
            component:Task
        },
        {
            path:'/',
            component:MLayout,
            children:[
                {
                    // 当 /user/:id/profile 匹配成功，
                    // UserProfile 会被渲染在 User 的 <router-view> 中
                    path: '',
                    redirect:'home'
                  },
                  {
                    path:'home',
                    component:Home
                },
                {
                    path:'program',
                    component:Program
                },
                {
                    path:'about',
                    component:About
                },
                {
                    path:'game',
                    component:Game
                },
                {
                    path:'message',
                    component:Message
                },
                {
                    path:'travel',
                    component:Travel
                },
                {
                    path:'personal',
                    component:Personal
                },
                {
                    path:'update',
                    component:UpdateRecord
                },
                {
                    path:'book',
                    component:Book
                },
                {
                    path:'memory',
                    component:Memory
                },
                {
                    path:'detail/:id',
                    component:Details
                },
                {
                    path:'/diary/detail/:id',
                    component:DiaryDetail
                }
            ]
        },
       
        
    ]
})