<template>
  <b-carousel
    v-model="slide"
    :interval="4000"
    v-if="bannerList"
    controls
    indicators
    background="#ababab"
    :img-width="width"
    :img-height="height"
    style="text-shadow: 1px 1px 2px #333;"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <template v-for="(item, index) in bannerList" >
    <template v-if="item.imgUri">
      <b-carousel-slide
        
        v-bind:key="index"
        class="car-img"
        :caption="item.title"
        :text="item.subTitle"
        @load="onLoading"
      >
       <img slot="img" class="d-block img-fluid w-100"
             :src="`${$store.state.imgUrl}${item.imgUri}`" :alt="item.title" v-on:click="onViewDetail(item)">
      </b-carousel-slide>
    </template>
    <template v-else>
      <b-carousel-slide caption="默认" img-blank img-alt="默认..."  v-bind:key="index">
      </b-carousel-slide>
    </template>
    </template>
  </b-carousel>
</template>
<script>
import { get } from "@/utils";
export default {
  name: "Carousel",
  props:{
    height:{
      type:Number,
      default:310,
    },
    width:{
      type:Number,
      default:800,
    }
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      show: true,
      bannerList: null,
    };
  },
  methods: {
    onViewDetail(data){
      if(data.articleId){
        this.$router.replace({path:`/detail/${data.articleId}`})
      }
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    onLoading() {
      setTimeout(() => {
        this.show = false;
      }, 3000);
    },
    initData() {
      if (!this.bannerList) {
        get("/article/bannerList").then((data) => {
          if (data.code === 200) {
            this.bannerList = data.data;
          }
        });
      }
    },
  },
  mounted() {
    this.onLoading();
  },
  beforeMount() {
    this.initData();
  },
};
</script>
<style scoped>
.car-img {
  cursor: pointer;
}
</style>
