import { render, staticRenderFns } from "./MiddleChildMenu.vue?vue&type=template&id=4c935038&scoped=true&"
import script from "./MiddleChildMenu.vue?vue&type=script&lang=js&"
export * from "./MiddleChildMenu.vue?vue&type=script&lang=js&"
import style0 from "../../css/layout/MiddleChildMenu.css?vue&type=style&index=0&id=4c935038&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c935038",
  null
  
)

export default component.exports