import { render, staticRenderFns } from "./MiddleHeader.vue?vue&type=template&id=aa3b3242&scoped=true&"
import script from "./MiddleHeader.vue?vue&type=script&lang=js&"
export * from "./MiddleHeader.vue?vue&type=script&lang=js&"
import style0 from "../../css/layout/MiddleHeader.css?vue&type=style&index=0&id=aa3b3242&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa3b3242",
  null
  
)

export default component.exports