<template>
  <div :class="$store.state.headerScroll?`header sticky`:`header`">
      <top-bar />
      <middle-header :menuList="menuList" />
      <!-- <navbar-header :menuList="menuList" /> -->
  </div>
</template>

<script>
import { menuList } from "@/service"
import TopBar from './Topbar';
import { get } from '@/utils';
import MiddleHeader from './MiddleHeader';
import Bus from "@/utils/EventBus";
export default {
  name: 'Header',
  props: {
    msg: String
  },
  components: {
    TopBar,
    // NavbarHeader,
    MiddleHeader,
  },
  data(){
    return {
      menuList:menuList
    }
  },
  methods:{
    initMenuList(){
      get("/menu/menuListForUser").then(res=>{
        if(res.code == 200){
          this.menuList = res.data;
        }
      })
    }
  },
  mounted(){
    this.initMenuList();
    Bus.$on("initMenuList",this.initMenuList);
  },
  beforeDestroy(){
    Bus.$off("initMenuList",this.initMenuList);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../css/layout/Header.css">
</style>
