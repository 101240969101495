<template>
<div>
    <div class="player">
  <div class="head">
    <div class="back"></div>
    <div class="front">
      <div class="avatar">
          <img :src="playInfo.avatar"/>
      </div>
      <div class="infos">
        <div class="titulo_song">{{playInfo.name}}</div>
        <div class="duracao_song">
            <i class="fa fa-clock-o">
             {{playInfo.publishTime}}
            </i>
        </div>
        <div class="tags">
            <template v-if="playInfo.authorList">
            <span v-for="(item,index) in playInfo.authorList" v-bind:key="index">{{item.name}}</span>
            </template>
            <template v-else>
                <span>未知</span>
            </template>    
        </div>
      </div>
    </div>
  </div>
  <div class="timeline">
    <div class="soundline"></div>
    <div class="controllers active"> 
        <b-overlay
                :show="playBusy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                      >
            <div class="back" @click="previousSongs"></div>
      </b-overlay>     
      <b-overlay
                :show="playBusy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                      ><div :class="playInfo.playStatusIcon" @click="playExe"></div></b-overlay>     
      <b-overlay
                :show="playBusy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                      ><div class="forward" @click="nextSongs"></div></b-overlay>     
    </div>
  </div>
  <audio ref="successpay" v-if="playInfo.songsSrc">
    <source :src="playInfo.songsSrc" type="audio/mpeg">
 </audio>
</div>
<div class="rotation"></div>
</div>
</template>
<script>
import { get } from "@/utils"
export default {
    name:"Music",
    data(){
        return {
            detailInfo:{
                ids: '%5B1297802566%5D',
                id:'1297802566',
            },
            songsInfo:{
                // type: 'song',
                // id:'1297802566',
                // search_type:1,
                csrf_token:'3d171c7a0f2855a09877bba07bff61f9'
            },
            playInfo:{
                avatar:'http://vignette4.wikia.nocookie.net/lyricwiki/images/1/15/Semisonic_portrait.jpg/revision/latest?cb=20090409134209',
                name:"歌曲正在加载中...",
                publishTime:'加载中...',
                songsSrc:null,
                playStatusIcon:'play',
                authorList:null,
                playIndex:0
            },
            mockData:['1297802566','2618983922','1808234742','1410448577','414118616'],
            playBusy:true,
        };
    },
    methods:{
        initAudioDom(){
            this.$nextTick(() => {
                if(this.$refs.successpay){
                    this.$refs.successpay.load();
                    }
            });
        },
        initSongDetail(){
            this.playBusy = true;
            // https://api.imjad.cn/cloudmusic/?type=song&id=1311345316&search_type=1
            get('/music/api/song/detail/',this.detailInfo).then(res=>{
               if(res.code == 200 && res.songs && res.songs.length > 0){
                  this.playInfo.name =  res.songs[0].name;
                  this.playInfo.publishTime =  this.$moment(res.songs[0].album.publishTime).format("YYYY年MM月DD号");
                  this.playInfo.avatar =  res.songs[0].album.picUrl;
                  this.playInfo.authorList =  res.songs[0].artists;
               }
            })
            get('/music/weapi/song/enhance/player/url/v1',this.songsInfo).then(res=>{
               if(res.code == 200 && res.data && res.data.length > 0){
                  this.playInfo.songsSrc =  res.data[0].url;
               }
               this.playBusy = false;
            })
        },
        playExe(){
            const status = this.playInfo.playStatusIcon;
            switch(status){
                case 'stop':
                     this.playInfo.playStatusIcon = 'play';
                    break;
                case 'pause':
                     this.playInfo.playStatusIcon = 'play';
                     this.$nextTick(() => {
                      this.$refs.successpay.pause();
                       });
                    break;
                case 'play':
                    this.playInfo.playStatusIcon = 'pause';
                      this.$nextTick(() => {
                     this.$refs.successpay.play();
                      });
                    break;
                default:

                    break;
            }
           
        },
        nextSongs(){
            const songsLength = this.mockData.length;
            if(this.playInfo.playIndex < songsLength){
                const payIndex = this.playInfo.playIndex + 1;
                this.songsInfo.id = this.mockData[payIndex];
                this.detailInfo.id = this.mockData[payIndex];
                this.playInfo.playIndex = payIndex;
                this.initSongDetail();
                this.playInfo.playStatusIcon = 'play';
                this.playExe();
            }
        },
        previousSongs(){
            if(this.playInfo.playIndex > 0){
                const payIndex = this.playInfo.playIndex - 1;
                this.songsInfo.id = this.mockData[payIndex];
                this.detailInfo.id = this.mockData[payIndex];
                this.playInfo.playIndex = payIndex;
                this.initSongDetail();
                this.playInfo.playStatusIcon = 'play';
                this.playExe();
            }
        }

    },
    created(){
        this.initSongDetail();
        this.initAudioDom();
    }
}
</script>
<style scoped lang="scss">
@import "../../css/tools/Music.scss";
</style>