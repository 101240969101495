import Vue from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'font-awesome/css/font-awesome.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import moment from 'moment';
import VueCookies from 'vue-cookies'
import router from './router';
import store from './vuex/store';
import axios from 'axios';
import VueQuillEditor from 'vue-quill-editor';
import VeeValidate from 'vee-validate';
import zh_CN from 'vee-validate/dist/locale/zh_CN';
import VueI18n from 'vue-i18n';
import ImgUpload from 'vue-image-crop-upload';
import VueAnime from 'vue-animejs';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
import "quill-emoji/dist/quill-emoji.css";
// import animate from 'animate.css';
import * as Emoji from "quill-emoji";
VueQuillEditor.Quill.register("modules/emoji", Emoji);

//  vue 
Vue.use(VueQuillEditor)
//  表单验证
Vue.use(VueI18n)
const i18n = new VueI18n({
       locale: 'zh_CN',
})
Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  locale: 'zh_CN',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors',
  i18n,
  i18nRootKey: 'validation',
  dictionary: {
    zh_CN
  }
}) 
//  动画
// Vue.use(animate)
Vue.use(VueAnime)
Vue.use(ImgUpload)

Vue.prototype.$axios = axios
// es2015 module
Vue.use(VueCookies)
Vue.config.productionTip = false
Vue.prototype.$moment = moment
//  init entity
// Install BootstrapVue
Vue.use(BootstrapVue,{
  breakpoints: [`xs`, 'sm', 'md', 'lg', 'xl', 'xxl']
})
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
