<template>
  <div>
    <div
      :class="`single-comment ${
        levelType === 1
          ? 'one-level'
          : levelType === 2
          ? 'two-level'
          : levelType === 3
          ? 'three-level'
          : 'three-level'
      }`"
    >
      <!-- <img :src="commentObj.avatar" alt="#" /> -->
      <b-avatar
        :variant="commentObj.avatar?'light':'primary'"
        class="avatar"
        :text="!commentObj.avatar ? commentObj.author.substring(0, 1) : null"
        :src="commentObj.avatar?`${$store.state.imgUrl}/${commentObj.avatar}`:null"
      ></b-avatar>
      <div class="content">
        <h4>
          <a href="javascript:;">{{ commentObj.author }}</a>
          <a
            href="javascript:;"
            class="ml-1 mr-2 commenter-badge staff"
            v-if="articleUserId == commentObj.userId"
            >作者</a
          >
          <template v-if="levelType > 1">
            <a href="javascript:;" class="ml-2 mr-2"
              ><i class="fa fa-reply fa-flip-horizontal" aria-hidden="true"></i
            ></a>
            <a href="javascript:;" class="form-author">{{ parentAuthor }}</a>
          </template>
          <span>
            <!-- {{$moment(commentObj.createDate).format("YYYY年MM月DD日 HH时")}} -->
            {{ commentObj.time }}
          </span>
        </h4>
        <p
          v-html="
            showAllComment
              ? commentObj.content.length > 100
                ? commentObj.content.substring(0, 30) + `......`
                : commentObj.content
              : commentObj.content
          "
        ></p>
        <div class="button">
          <a
            href="javascript:;"
            @click="clickReply(commentObj)"
            class="btn"
            v-if="
              (parentUserId && parentUserId != commentObj.userId) ||
                !parentUserId
            "
            ><i class="fa fa-reply" aria-hidden="true"></i>回复</a
          >
          <a
            href="javascript:;"
            :class="`btn ml-2 ${
              commentObj.operType && commentObj.operType === 1
                ? `active animated flipInY`
                : ``
            }`"
            @click="onClickLike(commentObj.gid)"
            ><i class="fa fa-thumbs-up" aria-hidden="true"></i>支持 ({{
              commentObj.like
            }})</a
          >
          <a
            href="javascript:;"
            :class="`btn ml-2 ${
              commentObj.operType && commentObj.operType === 2
                ? `active animated flipInY`
                : ``
            }`"
            @click="onClickDespise(commentObj.gid)"
            ><i class="fa fa-thumbs-down" aria-hidden="true"></i>反对 ({{
              commentObj.despise
            }})</a
          >
        </div>
      </div>
    </div>
    <template v-if="commentObj.child">
      <comment-two
        v-for="threeItem in commentObj.child"
        :commentObj="threeItem"
        :articleId="articleId"
        :parentUserId="parentUserId"
        :parentAuthor="commentObj.author"
        v-bind:key="threeItem.gid"
        :leftWidth="levelType <= 3 ? levelType * 110 : 3 * 110"
        :levelType="levelType + 1"
      />
    </template>
  </div>
</template>
<script>
import Bus from "@/utils/EventBus";
import { post } from "@/utils";
export default {
  name: "CommentTwo",
  props: {
    leftWidth: {
      type: Number,
      default: 0,
    },
    commentObj: {
      type: Object,
      require: true,
    },
    levelType: {
      type: Number,
      default: 1,
    },
    parentAuthor: {
      type: String,
    },
    parentUserId: {
      type: Number,
    },
    articleId: {
      type: String,
      require: true,
    },
    articleUserId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      showAllComment: false,
      delta: {
        ops: [
          {
            insert: "@小马",
            attributes: { link: "http://wikipedia.org", bold: true },
          },
          { retain: 1 },
        ],
      },
    };
  },
  methods: {
    onClickLike(commentId) {
      post("/comment/oper", {
        commentId: commentId,
        articleId: this.articleId,
        likes: true,
      }).then((res) => {
        if (res.code === 200 && res.data) {
          this.$bvToast.toast(res.msg, {
            title: "系统提示",
            variant: "success",
          });
          this.commentObj.like = res.data.likes;
          this.commentObj.despise = res.data.despise;
          this.commentObj.operType = 1;
        }
      });
    },
    onClickDespise(commentId) {
      post("/comment/oper", {
        commentId: commentId,
        articleId: this.articleId,
        despise: true,
      }).then((res) => {
        if (res.code === 200 && res.data) {
          this.$bvToast.toast(res.msg, {
            title: "系统提示",
            variant: "success",
          });
          this.commentObj.like = res.data.likes;
          this.commentObj.despise = res.data.despise;
          this.commentObj.operType = 2;
        }
      });
    },
    clickReply(data) {
      Bus.$emit("initUserReply", data);
    },
  },
};
</script>
<style scoped>
.single-comment {
  position: relative;
  margin-bottom: 40px;
  border-radius: 5px;
  padding-left: 95px;
}
img {
  border: 0;
}
img,
a,
input,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
img {
  height: auto;
  max-width: 100%;
}
.single-comment img {
  height: 70px;
  width: 70px;
  border-radius: 100%;
  position: absolute;
  left: 0;
}
.avatar {
  /* height: 70px; */
  /* width: 70px; */
  border-radius: 100%;
  position: absolute;
  left: 0;
}
.single-comment .content {
}
.single-comment .content h4 {
  color: #333;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
  margin-bottom: 18px;
  text-transform: capitalize;
}
.single-comment .content h4 span {
  display: inline-block;
  font-size: 13px;
  color: #8d8d8d;
  margin: 0;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  margin-top: 5px;
}
.content p {
  margin-bottom: 25px;
  line-height: 26px;
}
.single-comment .content p {
  color: #666;
  font-weight: 400;
  display: block;
  margin: 0;
  margin-bottom: 20px;
  line-height: 22px;
}
.single-comment .content .button {
}
a:visited {
  color: inherit;
}
.single-comment .content .btn {
  display: inline-block;
  color: #666;
  font-weight: 400;
  color: #6a6a6a;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 14px;
  background: transparent;
  padding: 0;
}
.single-comment .content a i {
  display: inline-block;
  margin-right: 5px;
}
.commenter-badge {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 10px;
  text-transform: uppercase;
  display: inline-block;
  background: #0078ff;
  color: #fff !important;
  padding: 4px 5px;
  border-radius: 2px;
  margin-left: 3px;
}
.form-author {
  color: #666 !important;
}
.single-comment a.active {
  color: #0078ff !important;
}
.avatar {
  height: 4rem;
  width: 4rem;
}
.one-level {
  margin-left: 0px;
}
.two-level {
  margin-left: 110px;
}
.three-level {
  margin-left: 220px;
}
@media only screen and (max-width: 768px) {
  .single-comment {
    margin-bottom: 25px;
    padding-left: 65px;
  }
  .avatar {
    height: 3rem;
    width: 3rem;
  }
  .one-level {
    margin-left: 0px;
  }
  .two-level {
    margin-left: 65px;
  }
  .three-level {
    margin-left: 130px;
  }
}
@media only screen and (max-width: 576px) {
  .single-comment {
    margin-bottom: 15px;
    padding-left: 45px;
  }
  .avatar {
    height: 2rem;
    width: 2rem;
  }
  .one-level {
    margin-left: 0px;
  }
  .two-level {
    margin-left: 45px;
  }
  .three-level {
    margin-left: 90px;
  }
  .single-comment .content .btn {
    font-size: 7px;
  }
  .single-comment .content h4 {
    font-size: 7px;
  }
}
</style>
