<template>
  <div id="app" 
   :class="`${$store.state.template}`" @scroll="scrollspyFuntion">
    <span class="content_full_point" id="content_full_point"></span>
    <router-view/>
     <transition name="fade">
      <a  href="javascript:;" class="scrollUp" v-if="!isTop" :style="`position: fixed; z-index: 2147483647;`"  @click="backUp"><i class="fa fa-angle-up"></i></a>
    </transition>
  </div>
</template>

<script>
import Bus from "@/utils/EventBus";
import {  get } from "@/utils";
export default {
  name: "App",
  metaInfo: {
    title: 'Liuxd 个人博客',
    description: 'Liuxd 个人博客',
    keywords: '',
    // override the parent template and just use the above title only
     titleTemplate: null
  },
  components: {
  },
  data() {
    return {
      show: true,
      pointer: null,
      scrollY: 250,
      scrollDuration: 1000,
      userInfo:{
        userId:null,
        nickname:null,
        username:null,
        email:null,
        enabled:null,
        synopsis:null,
        createDate:null,
      }
    };
  },
  created() {},
  methods: {
    scrollListerInfo (el) {
      this.$store.state.mainPageScrollTop = el.target.scrollTop;
      const anchor = this.$el.querySelectorAll("#app");
     this.$store.state.mainGroupListScrollTop = this.queryEleScroll(anchor,el.target.scrollTop);
    },
    queryEleScroll(list,scrollTop){
      let count  = 0;
      for (const key in list) {
          if(scrollTop>= (list[key].offsetTop) ){
            count =  key;
        }
      }
      return count;
    },
    backUp(){
      const content = this.$el.getElementsByClassName("content_full_point")[0];
      if(content){
        content.scrollIntoView({behavior:'smooth'});
      }
    },
    scrollspyFuntion(e) {
      this.scrollListerInfo(e);
      this.pointer = e.srcElement.scrollTop;
      this.$store.state.clientWidth = e.srcElement.clientWidth;
      if (e.srcElement.scrollTop && e.srcElement.scrollTop > 148) {
        this.$store.state.headerScroll = true;
        this.$store.state.detailOperScroll = true;
      } else {
        this.$store.state.headerScroll = false;
        this.$store.state.detailOperScroll = false;
      }
    },
    getUserInfo() {
      get("/user/info").then((res) => {
        if (res.code === 200) {
          this.$store.state.userInfo = res.data;
          this.userInfo.userId = res.data.userId;
          this.userInfo.nickname = res.data.nickname;
          this.userInfo.username = res.data.username;
          this.userInfo.email = res.data.email;
          this.userInfo.enabled = res.data.enabled;
          this.userInfo.synopsis = res.data.synopsis;
          this.userInfo.createDate = res.data.createDate;
          if( this.$cookies.isKey("liuxd-blog-login")){
            this.$cookies.remove("liuxd-blog-login");
          }
          this.$cookies.set("liuxd-blog-login",true)
        }
      });
      return this.$store.state.userInfo;
    }
  },
  computed:{
    isTop(){
      return this.pointer < this.scrollY;
    }
  },
  mounted() {
    this.show = false;
    Bus.$on("getUserInfo", this.getUserInfo);
  },
  beforeDestroy() {
    Bus.$off("getUserInfo", this.getUserInfo);
  },
};
</script>

<style src="./css/public.css">
</style>
