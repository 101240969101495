<template>
  <div class="author-details" v-if="userInfo">
    <div class="author-wrap">
      <div class="author">
        <div class="author-avatar">
          <a href="javascript:;"><b-img-lazy
              :alt="userInfo.author"
              v-bind="mainProps"
              :src="`${$store.state.imgUrl}/${userInfo.headPortraitImg}`"
              class="avatar avatar-180 photo"
          ></b-img-lazy></a>
        </div>
        <div class="author-description">
          <h3 class="author-name">
            <a href="javascript:;">{{userInfo.author}}</a>
          </h3>
          <div class="author-bio">{{userInfo.synopsis}}</div>
          <!-- <div class="author-social">
            <a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link facebook-social-icon ja-circle"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13px"
                height="13px"
                viewBox="0 0 602 1024"
              >
                <path
                  d="M566.846 7.384v162.462h-96.615q-52.923 0-71.385 22.153t-18.462 66.461v116.308h180.308l-24 182.154h-156.308v467.077h-188.308v-467.077h-156.923v-182.154h156.923v-134.154q0-114.462 64.001-177.539t170.462-63.077q90.462 0 140.308 7.384z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link twitter-social-icon ja-circle"
              ><svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="13px"
                height="13px"
              >
                <path
                  d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link linkedin-social-icon ja-circle"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13px"
                height="13px"
                viewBox="0 0 878 1024"
              >
                <path
                  d="M199.493 365.095v566.47h-188.632v-566.47h188.632zM211.497 190.181q0.571 41.728-28.866 69.737t-77.454 28.009h-1.143q-46.872 0-75.454-28.009t-28.58-69.737q0-42.3 29.439-70.023t76.882-27.723 76.025 27.723 29.152 70.023zM877.999 606.887v324.676h-188.061v-302.955q0-60.019-23.151-94.031t-72.309-34.011q-36.012 0-60.306 19.72t-36.298 48.873q-6.288 17.149-6.288 46.301v316.103h-188.061q1.143-228.074 1.143-369.834t-0.571-169.198l-0.571-27.438h188.061v82.313h-1.143q11.433-18.292 23.437-32.010t32.296-29.724 49.73-24.865 65.45-8.86q97.746 0 157.194 64.878t59.448 190.062z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link flickr-social-icon ja-circle"
              ><svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="13px"
                height="13px"
              >
                <path
                  d="M0 12c0 3.074 2.494 5.564 5.565 5.564 3.075 0 5.569-2.49 5.569-5.564S8.641 6.436 5.565 6.436C2.495 6.436 0 8.926 0 12zm12.866 0c0 3.074 2.493 5.564 5.567 5.564C21.496 17.564 24 15.074 24 12s-2.492-5.564-5.564-5.564c-3.075 0-5.57 2.49-5.57 5.564z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link youtube-social-icon ja-circle"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="13px"
                height="13px"
                viewBox="0 0 24 24"
              >
                <path
                  d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link pinterest-social-icon ja-circle"
              ><svg
                viewBox="0 0 24 24"
                width="13px"
                height="13px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link behance-social-icon ja-circle"
              ><svg
                viewBox="0 0 24 24"
                width="13px"
                height="13px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.938 4.503c.702 0 1.34.06 1.92.188.577.13 1.07.33 1.485.61.41.28.733.65.96 1.12.225.47.34 1.05.34 1.73 0 .74-.17 1.36-.507 1.86-.338.5-.837.9-1.502 1.22.906.26 1.576.72 2.022 1.37.448.66.665 1.45.665 2.36 0 .75-.13 1.39-.41 1.93-.28.55-.67 1-1.16 1.35-.48.348-1.05.6-1.67.767-.61.165-1.252.254-1.91.254H0V4.51h6.938v-.007zM16.94 16.665c.44.428 1.073.643 1.894.643.59 0 1.1-.148 1.53-.447.424-.29.68-.61.78-.94h2.588c-.403 1.28-1.048 2.2-1.9 2.75-.85.56-1.884.83-3.08.83-.837 0-1.584-.13-2.272-.4-.673-.27-1.24-.65-1.72-1.14-.464-.49-.823-1.08-1.077-1.77-.253-.69-.373-1.45-.373-2.27 0-.803.135-1.54.403-2.23.27-.7.644-1.28 1.12-1.79.495-.51 1.063-.895 1.736-1.194s1.4-.433 2.22-.433c.91 0 1.69.164 2.38.523.67.34 1.22.82 1.66 1.4.44.586.75 1.26.94 2.02.19.75.25 1.54.21 2.38h-7.69c0 .84.28 1.632.71 2.065l-.08.03zm-10.24.05c.317 0 .62-.03.906-.093.29-.06.548-.165.763-.3.21-.135.39-.328.52-.583.13-.24.19-.57.19-.96 0-.75-.22-1.29-.64-1.62-.43-.32-.99-.48-1.69-.48H3.24v4.05H6.7v-.03zm13.607-5.65c-.352-.385-.94-.592-1.657-.592-.468 0-.855.074-1.166.238-.302.15-.55.35-.74.59-.19.24-.317.48-.392.75-.075.26-.12.5-.135.71h4.762c-.07-.75-.33-1.3-.68-1.69v.01zM6.52 10.45c.574 0 1.05-.134 1.425-.412.374-.27.554-.72.554-1.338 0-.344-.07-.625-.18-.846-.13-.22-.3-.39-.5-.512-.21-.124-.45-.21-.72-.257-.27-.053-.56-.074-.84-.074H3.23v3.44h3.29zm9.098-4.958h5.968v1.454h-5.968V5.48v.01z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link dribbble-social-icon ja-circle"
              ><svg
                viewBox="0 0 24 24"
                width="13px"
                height="13px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 24C5.385 24 0 18.615 0 12S5.385 0 12 0s12 5.385 12 12-5.385 12-12 12zm10.12-10.358c-.35-.11-3.17-.953-6.384-.438 1.34 3.684 1.887 6.684 1.992 7.308 2.3-1.555 3.936-4.02 4.395-6.87zm-6.115 7.808c-.153-.9-.75-4.032-2.19-7.77l-.066.02c-5.79 2.015-7.86 6.025-8.04 6.4 1.73 1.358 3.92 2.166 6.29 2.166 1.42 0 2.77-.29 4-.814zm-11.62-2.58c.232-.4 3.045-5.055 8.332-6.765.135-.045.27-.084.405-.12-.26-.585-.54-1.167-.832-1.74C7.17 11.775 2.206 11.71 1.756 11.7l-.004.312c0 2.633.998 5.037 2.634 6.855zm-2.42-8.955c.46.008 4.683.026 9.477-1.248-1.698-3.018-3.53-5.558-3.8-5.928-2.868 1.35-5.01 3.99-5.676 7.17zM9.6 2.052c.282.38 2.145 2.914 3.822 6 3.645-1.365 5.19-3.44 5.373-3.702-1.81-1.61-4.19-2.586-6.795-2.586-.825 0-1.63.1-2.4.285zm10.335 3.483c-.218.29-1.935 2.493-5.724 4.04.24.49.47.985.68 1.486.08.18.15.36.22.53 3.41-.43 6.8.26 7.14.33-.02-2.42-.88-4.64-2.31-6.38z"
                ></path></svg></a
            ><a
              href="javascript:;"
              rel="external noopener"
              target="_blank"
              class="social-link instagram-social-icon ja-circle"
              ><svg
                viewBox="0 0 24 24"
                width="13px"
                height="13px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
                ></path></svg
            ></a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Synopsis",
  props: {
    userInfo: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
       mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: '#bbb',
          width: 180,
          height: 180,
        }
    };
  },
  methods: {},
};
</script>
<style scoped src="../../css/layout/Synopsis.css"></style>
