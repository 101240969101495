<template>
  <b-container :fluid="$store.state.isFullWidth">
    <b-row>
      <b-col lg="8" cols="12" sm="12" xl="8" xs="12">
        <template v-if="articleList">
          <b-row v-for="(item, index) in articleList" v-bind:key="index">
            <b-col cols="12">
              <b-card no-body class="content-card" overlay>
                <b-row class="more-show-read" no-gutters>
                  <b-col md="6" class="content-col-img">
                    <b-card-img
                      v-if="item.homeImgUrl"
                      :src="`${$store.state.imgUrl}${item.homeImgUrl}`"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                    <b-skeleton-img
                      v-else
                      card-img="left"
                      height="273px"
                    ></b-skeleton-img>
                    <router-link
                      :to="`/detail/${item.articleId}`"
                      class="bizwheel-btn theme-2"
                      >查看更多</router-link
                    >
                  </b-col>
                  <b-col md="6">
                    <b-card-body class="news-body">
                      <b-card-title>
                        <router-link :to="`/detail/${item.articleId}`">{{
                          item.title
                        }}</router-link>
                      </b-card-title>
                      <b-card-sub-title class="mt-3">
                        {{ item.fullText }}
                      </b-card-sub-title>
                      <b-card-text>
                        <ul class="news-meta">
                          <li class="date">
                            <i class="fa fa-calendar fa-2x"></i
                            >{{
                              $moment(item.createDate).format("YYYY年MM月DD日")
                            }}
                          </li>
                          <li
                            class="view"
                            v-b-tooltip.hover
                            :title="`评论量：${item.commentCount}`"
                          >
                            <i class="fa fa-comments fa-2x"></i
                            >{{ item.commentCount }}
                          </li>
                          <li
                            class="eye"
                            v-b-tooltip.hover
                            :title="`阅读量：${item.readCount}`"
                          >
                            <i class="fa fa-eye fa-2x"></i>{{ item.readCount }}
                          </li>
                        </ul>
                      </b-card-text>

                      <b-card-text></b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-if="total > 0"
              v-model="pageNo"
              :total-rows="total"
              :per-page="pageSize"
              first-text="首页"
              prev-text="上一页"
              next-text="下一页"
              last-text="尾页"
              next-class="v-next-page-style"
              last-class="v-last-page-style"
              page-class="v-page-style"
              first-class="v-first-page-style"
              prev-class="v-prev-page-style"
              class="mb-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4" cols="12" xl="4" sm="12" xs="12" class="sidebar-program">
        <sidebar />
      </b-col>
      <div class="mod-side-bar">
        <a
          href="javascript:;"
          class="p-1 text-wrap"
          v-b-toggle.sidebar-program-list
          ><i class="fa fa-list fa-2x" aria-hidden="true"></i
        ></a>
      </div>
      <b-sidebar
        id="sidebar-program-list"
        backdrop-variant="dark"
        backdrop
        shadow
      >
        <sidebar />
      </b-sidebar>
    </b-row>
      <!--/ End News Tags -->
    <b-modal id="modal-frinendly-link" centered no-fade title="申请友情链接" hide-footer>
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group id="input-group-1"  label-for="input-1" description="为了保证后续能联系到您，请正确的填写。">
                <b-form-input id="input-1" v-model="form.email" type="email" placeholder="请输入您的联系邮箱地址" required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3"  label-for="input-3">
                <b-form-input id="input-3" v-model="form.websiteName" placeholder="请输入网站名称" required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label-for="input-2">
                <b-form-input id="input-2" v-model="form.linkAddress" placeholder="请输入有效的网站地址" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4"  label-for="input-4">
                <b-form-textarea id="input-4" v-model="form.leaveMessage" placeholder="请输入留言信息" required></b-form-textarea>
            </b-form-group>

            <b-button type="submit" variant="primary" class="mr-2" squared>提交</b-button>
            <b-button type="reset" variant="danger" squared>重置</b-button>
        </b-form>
    </b-modal>
  </b-container>
</template>
<script>
import EventBus from "@/utils/EventBus";
import Sidebar from "./child/Sidebar";
import { get, post } from "@/utils";
export default {
  name: "Program",
  components: {
    Sidebar,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 1,
      readmore: false,
      id: 1,
      articleList: null,
      pageNo: 1,
      pageSize: 5,
      total: 0,
      searchText:null,
      classifyId:null,
      articleTagId:null,
       form: {
          email: '',
          linkAddress: '',
          websiteName: '',
          leaveMessage: ''
      },
        show: true
    };
  },
  methods: {
     onSubmit(event) {
        event.preventDefault()
        post('/article/saveFriendlyLink',this.form).then(res=>{
          this.$bvModal.hide('modal-frinendly-link');
          if(res.code === 200){
             this.$bvToast.toast("申请提交成功，请耐心等待审核", {
              title: "系统提示",
              variant:'success'
            });
          }
        })
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
    },
    onClickViewDetail(articleId) {
      this.$router.push({ path: `/detail/${articleId}` });
    },
    mouseoverReadMore() {
      this.readmore = true;
    },
    mouseoutReadMore() {
      this.readmore = true;
    },
    initArticleData(searchText,classifyId,articleTagId) {
      this.searchText = searchText;
      this.classifyId = classifyId;
      this.articleTagId = articleTagId;
      this.$store.state.loading = true;
      get("/article/homeList", {
        page: this.pageNo - 1,
        size: this.pageSize,
        searchText: searchText,
        type: 1,
        classifyId:classifyId,
        articleTagId:articleTagId,
      }).then((res) => {
        this.$store.state.loading = false;
        if (res.code === 200 && res.data) {
          this.articleList = res.data.content;
          this.total = res.data.totalElements;
        }
      });
    },
    handleMouseMove(e) {
      const el = document.getElementById("wrapper");
      const d = el.getBoundingClientRect();
      let x = e.clientX - (d.left + Math.floor(d.width / 2));
      let y = e.clientY - (d.top + Math.floor(d.height / 2));
      // Invert values
      x = x - x * 2;
      y = y - y * 2;
      document.documentElement.style.setProperty("--scale", 1.6);
      document.documentElement.style.setProperty("--x", x / 2 + "px");

      document.documentElement.style.setProperty("--y", y / 2 + "px");
    },

    handleMouseLeave() {
      document.documentElement.style.setProperty("--scale", 1);
      document.documentElement.style.setProperty("--x", 0);
      document.documentElement.style.setProperty("--y", 0);
    },
  },
  mounted() {
    EventBus.$on("initArticleData", this.initArticleData);
  },
  beforeDestroy() {
    EventBus.$off("initArticleData", this.initArticleData);
  },
  watch: {
    pageNo(newValue, oldValue) {
      if (newValue != oldValue) {
        this.initArticleData(this.searchText,this.classifyId,this.articleTagId);
      }
    },
  },
  created() {
    this.initArticleData(null,null,null);
  },
};
</script>
<style scoped src="../css/BlogList.css"></style>
