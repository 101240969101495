<template>
  <b-container :fluid="$store.state.isFullWidth">
    <b-row>
      <b-col cols="12" lg="8" col>
        <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="2" v-if="articleList">
          <b-col v-for="(item, index) in articleList" v-bind:key="index">
            <card-list :article="item" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
          <b-pagination
            v-if="total > 0"
            v-model="pageNo"
            :total-rows="total"
            :per-page="pageSize"
            first-text="首页"
            prev-text="上一页"
            next-text="下一页"
            last-text="尾页"
            next-class="v-next-page-style"
            last-class="v-last-page-style"
            page-class="v-page-style"
            first-class="v-first-page-style"
            prev-class="v-prev-page-style"
            class="mb-0"
          ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="4" col> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { get } from "@/utils";
import CardList from "./child/CardList";
export default {
  name: "travel",
  components: {
    CardList,
  },
  data() {
    return {
      articleList: null,
      pageNo: 1,
      pageSize: 5,
      total: 0,
    };
  },
  methods: {
    initArticleData() {
      get("/article/homeList", {
        type: 3,
        page: this.pageNo - 1,
        size: this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
          this.articleList = res.data.content;
          this.total = res.data.totalElements;
        }
      });
    },
  },
  watch:{
    pageNo(newValue, oldValue){
      if(newValue != oldValue){
          this.initArticleData();
      }
    }
  },
  created() {
    this.initArticleData();
  },
};
</script>
