<template>
  <ul class="thm-upcoming-events-lists thm-events-lists" v-if="updateRecordList">
      <template v-for="(item,index) in updateRecordList">
    <li class="thm-upcoming-event clearfix first-item"  v-bind:key="index" @click="clickDetail(item)">
      <div class="thm-upcoming-event-info">
        <div class="thm-upcoming-event-date-wrap">
          <div class="thm-upcoming-event-date">
            {{$moment(item.createDate).format("MM月")}}<span class="thm-upcoming-event-date-day">{{$moment(item.createDate).format("DD")}}</span>
          </div>
          <div class="thm-upcoming-event-price-wrap">
            <span class="thm-upcoming-event-time">{{$moment(item.onlineTime).format("hh:mm")}} - {{$moment(item.offlineTime).format("hh:mm")}} </span
            ><span class="thm-upcoming-event-address"
              >上线时间间隔</span
            >
          </div>
        </div>
        <div class="thm-upcoming-event-price">
          状态:<span class="thm-price-box">{{item.status ===1?'普通更新':item.status ===2?'重大更新':'无'}}</span>
        </div>
        <h3 class="thm-upcoming-event-title">
          <a
            href="javascript:;" 
            >{{item.title}}</a
          >
        </h3>
      </div>
    </li>
      </template>
  </ul>
</template>
<script>
import Bus from "@/utils/EventBus";
export default {
  name: "EventRecord",
  props:{
      updateRecordList:{
          type:Array,

      }
  },
  methods:{
      clickDetail(data) {
      Bus.$emit("initUpdateRecordInfo", data);
    }
  }
};
</script>
<style scoped>
/*****Upcoming events css****/

.thm-upcoming-events-lists {
    padding: 0;
    margin: 0;
    list-style: none;
}
.thm-upcoming-events-lists .thm-upcoming-event {
    padding: 25px;
    margin: 0;
    margin-bottom: 30px;
    transition: all .4s;
}
.thm-upcoming-events-lists .thm-upcoming-event:not(:last-child) {
    border-bottom: 2px solid #fff;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-info .thm-upcoming-event-date {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    color: var(--languageschool-major-color);
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-info .thm-upcoming-event-title {
    font-size: 16px;
    display: block;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-info .thm-upcoming-event-title a {
    color: #1d252d;
    transition: 400ms;
    font-size: 19px;
}
.thm-upcoming-events-lists .thm-upcoming-event:hover .thm-upcoming-event-info .thm-upcoming-event-title a, .thm-upcoming-events-lists .thm-upcoming-event.first-item:hover .thm-upcoming-event-price, .thm-upcoming-events-lists .thm-upcoming-event.first-item:hover .thm-upcoming-event-date-wrap .thm-upcoming-event-date .thm-upcoming-event-date-day, .thm-upcoming-events-lists .thm-upcoming-event.first-item:hover .thm-upcoming-event-date-wrap .thm-upcoming-event-date {
    color: #fff;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-speakers-wrap {
    margin-bottom: 5px;
    margin-top: 23px;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-speakers-wrap .thm-upcoming-event-speaker {
    display: flex;
    align-items: center;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-speakers-wrap .thm-upcoming-event-speaker .thm-ue-speaker-media {
    margin-right: 15px;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-speakers-wrap .thm-upcoming-event-speaker .thm-ue-speaker-media img {
    width: 50px;
    border-radius: 100%;
    height: 50px;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-speakers-wrap .thm-upcoming-event-speaker .thm-ue-speaker-info .thm-ue-speaker-name {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}
.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-speakers-wrap .thm-upcoming-event-speaker .thm-ue-speaker-info .thm-ue-speaker-name a {
    color: #1d252d;
    transition: 400ms;
}
.thm-upcoming-events-lists .thm-upcoming-event:hover .thm-upcoming-event-speakers-wrap .thm-upcoming-event-speaker .thm-ue-speaker-info .thm-ue-speaker-name a, .thm-upcoming-events-lists .thm-upcoming-event:hover span.thm-price-box{
    color: #fff;
}

.thm-upcoming-events-lists .thm-upcoming-event .thm-upcoming-event-speakers-wrap .thm-upcoming-event-speaker .thm-ue-speaker-info .thm-ue-speaker-name small {
    display: block;
    font-size: 14px;
    opacity: 0.6;
}
.thm-upcoming-events-lists .thm-upcoming-event.first-item {
    background: #f6f6f6;
    padding-top: 0;
}
.thm-upcoming-events-lists .thm-upcoming-event.first-item:hover{
    background: #2e2751;
}
.thm-upcoming-events-lists .thm-upcoming-event.first-item .thm-upcoming-event-title {
    font-size: 26px;
}

.thm-upcoming-events-lists .thm-upcoming-event.first-item .thm-upcoming-event-date-wrap {
    margin: 0 -25px;
    display: flex;
}
.thm-upcoming-events-lists .thm-upcoming-event.first-item .thm-upcoming-event-date-wrap .thm-upcoming-event-date {
    padding: 8px 18px 14px 18px;
    margin: 0px;
    font-size: 20px;
    color: #1d252d;
    font-weight: 300;
    text-align: center;
}
.thm-upcoming-events-lists .thm-upcoming-event.first-item .thm-upcoming-event-date-wrap .thm-upcoming-event-date .thm-upcoming-event-date-day {
    display: block;
    font-size: 40px;
    font-weight: 600;
    line-height: 0.8;
    color: var(--languageschool-major-color);
}
.thm-upcoming-events-lists .thm-upcoming-event.first-item .thm-upcoming-event-date-wrap .thm-upcoming-event-price-wrap {
    background: #fff;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-size: 16px;
}
.thm-upcoming-events-lists .thm-upcoming-event.first-item .thm-upcoming-event-price {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
    margin-top: 30px;
    color: var(--languageschool-major-color);
}

@media(max-width:992px){
    .thm-upcoming-events-lists .thm-upcoming-event.first-item .thm-upcoming-event-title{
        font-size: 18px;
        line-height: 26px;
    }
    span.thm-upcoming-event-date-day{
        font-size: 26px;
    }
    .thm-upcoming-event-time, .thm-upcoming-event-address{
        font-size: 14px;
    }
    .thm-events-lists{
        display: block;
    }
    .thm-upcoming-events-lists .thm-upcoming-event{
        margin-bottom: 20px;
    }
}


</style>
