<template>
  <ul class="sub-menu">
    <li :class="item.child && item.child.length > 0?`icon-active`:null" v-bind:key="index" v-for="(item,index) in menuList">
      <router-link :to="item.path">{{item.label}}</router-link>
      <ChildMenu :menuList="item.child" v-if="item.child && item.child.length > 0"/>
    </li>
  </ul>
</template>
<script>
import ChildMenu from "@/components/child/ChildMenu";

export default {
  name: "ChildMenu",
  components:{
    ChildMenu,
  },
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {},
  mounted() {}
};
</script>
<style scoped>
  .sub-menu {
      background: #fff;
      width: 220px;
      text-align: left;
      position: absolute;
      top: 100%;
      z-index: 999;
      opacity: 0;
      visibility: hidden;
      padding: 20px;
      left: -32px;
      margin: 0;
      -webkit-box-shadow: 1px 4px 12px rgba(51, 51, 51, 0.25);
      -moz-box-shadow: 1px 4px 12px rgba(51, 51, 51, 0.25);
      box-shadow: 1px 4px 12px rgba(51, 51, 51, 0.25);
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
  }
  .sub-menu li a {
      padding: 6px 15px;
      color: #666;
      display: block;
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;
      background: transparent;
  }
 .sub-menu li.icon-active a::after {
      content: "\f107";
      position: relative;
      font-family: 'FontAwesome';
      padding-left: 6px;
  }
.sub-menu a::after{
	display:none;
}
  .sub-menu li {
      float: none;
      margin: 0;
      display: block;
  }
.sub-menu li:last-child{
      border:none;
  }
.sub-menu li a:before{
      display:none;
  }
  .sub-menu li:last-child a{
      border-bottom:0px;
  }
.sub-menu li:hover a{
      color:#fff;
      background:#179E66;
  }
.sub-menu li a:hover{
      border-color:transparent;
  }
.sub-menu li i {
      float: right;
      margin-top: 8px;
      font-size:10px;
      z-index:5;
  }
.sub-menu li .sub-menu {
      top: 0;
      left: initial;
      left: -122%;
      -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 3px 5px #3333334d;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      opacity: 0;
      visibility: hidden;
      padding: 10px;
  }
 .sub-menu li:hover .sub-menu{
      opacity:1;
      visibility:visible;
  }
.sub-menu li .sub-menu li a{
      padding: 8px 15px;
      color: #666;
      display: block;
      font-weight: normal;
      text-transform: capitalize;
      background: transparent;
  }
.sub-menu li:hover .sub-menu li a{
      background:transparent;
  }
 .sub-menu li .sub-menu li a:hover{
      color:#fff;
      background:#179E66;
  }
.sub-menu .sub-menu li:last-child a{
      border-bottom:0px solid;
  }
.sub-menu li .sub-menu .sub-menu {
      transform: scaleY(0.2);
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      opacity: 0;
      visibility: hidden;
      padding: 10px;
  }
.sub-menu li .sub-menu li:hover .sub-menu {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
  }
</style>