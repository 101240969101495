export const  menuList = [
        {
            label:"主页",
            name:"home",
            path:"/home",
            open:false,
            child:[]
        },
        {
            label:"程序之趣",
            name:"program",
            path:"/program",
            open:false,
            child:[]
        },
        {
            label:"生活随笔",
            name:"life",
            path:"/life",
            open:false,
            child:[
                {
                    label:"点滴记忆",
                    name:"memory",
                    open:false,
                    path:"/memory"
                },
                {
                    label:"旅游记事",
                    name:"travel",
                    open:false,
                    path:"/travel"
                },
                {
                    label:"游戏",
                    name:"game",
                    path:"/game",
                    open:false,
                },

            ]
        },
        {
            label:"关于我",
            name:"about",
            path:"/about",
            open:false,
            child:[
                {
                    label:"我的书单",
                    name:"book",
                    path:"/book",
                    open:false,
                    child:[]
                },
                {
                    label:"留言",
                    name:"message",
                    path:"/message",
                    open:false,
                    child:[]
                },
                {
                    label:"更新记录",
                    name:"update",
                    path:"/update",
                    open:false,
                    child:[]
                }
            ]
        },
    ];