<template>
  <b-container :fluid="$store.state.isFullWidth">
    <b-row>
      <b-col cols="12">
        <div class="section-title style2 text-center">
          <div class="section-top">
            <h1><span>个人日记</span><b>点滴记忆</b></h1>
            <h4>
              我不再装模做样的拥有很多朋友，而是回到了孤单之中，以真正的我开始了独自的生活。有时我也会因为寂寞而难以忍受空虚的折磨，但我宁愿以这样的方式来维护自己的自尊，也不愿以耻辱为代价去换取那种表面的朋友。
              ---《在细雨中呼喊》
            </h4>
          </div>
          <div class="section-bottom">
            <div class="text-style-two">
              <p>
                "Hope for the best, but prepare for the worst."
                Bad things might happen, so be prepared.
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <template v-if="diaryList">
        <b-col
          cols="12"
          lg="4"
          md="4"
          v-for="(item, index) in diaryList"
          v-bind:key="index"
        >
          <div class="single-service">
            <div class="service-head">
              <div class="featured">2020年11月12日</div>
              <img :src="$store.state.imgUrl+`${item.homeImgUrl}`" alt="#" />
              <div class="icon-bg"><i class="fa fa-handshake-o"></i></div>
            </div>
            <div class="service-content">
              <h4>
                <router-link :to="`/diary/detail/${item.diaryId}`">{{
                  item.title
                }}</router-link>
              </h4>
              <p>{{ item.famousRemark }}</p>
              <router-link class="btn" :to="`/diary/detail/${item.diaryId}`"
                ><i class="fa fa-arrow-circle-o-right"></i>查看详情</router-link
              >
            </div>
          </div>
        </b-col>
      </template>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-pagination
          v-if="total > 0"
          v-model="pageNo"
          :total-rows="total"
          :per-page="pageSize"
          first-text="首页"
          prev-text="上一页"
          next-text="下一页"
          last-text="尾页"
          next-class="v-next-page-style"
          last-class="v-last-page-style"
          page-class="v-page-style"
          first-class="v-first-page-style"
          prev-class="v-prev-page-style"
          class="mb-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { get } from "@/utils";
export default {
  name: "Memory",
  data() {
    return {
      diaryList: null,
      pageNo: 0,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    initArticleMemoryList() {
      get("/diary/list",{pageNo:this.pageNo,pageSize:this.pageSize}).then(res=>{
          if(res.code == 200){
              this.diaryList = res.data.content;
              this.total = res.data.totalElements;
          }
      });
    },
  },
  mounted(){
      this.initArticleMemoryList();
  }
};
</script>
<style scoped src="../css/Memory.css"></style>
