<template>
  <b-container :fluid="$store.state.isFullWidth">
    <b-row>
      <b-col cols="12">
        <b-row v-if="gameArticleList">
          <template v-for="(item, index) in gameArticleList">
            <b-col md="4" lg="4" xl="3" v-bind:key="index">
              <single-product :article="item" />
            </b-col>
          </template>
        </b-row>

        <b-row v-else>
          <b-col md="4" lg="4" xl="3">
            <b-card class="w-100">
              <b-skeleton animation="throb" width="85%"></b-skeleton>
              <b-skeleton animation="throb" width="55%"></b-skeleton>
              <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
          </b-col>
          <b-col md="4" lg="4" xl="3">
            <b-card class="w-100">
              <b-skeleton animation="throb" width="85%"></b-skeleton>
              <b-skeleton animation="throb" width="55%"></b-skeleton>
              <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
          </b-col>
          <b-col md="4" lg="4" xl="3">
            <b-card class="w-100">
              <b-skeleton animation="throb" width="85%"></b-skeleton>
              <b-skeleton animation="throb" width="55%"></b-skeleton>
              <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
          </b-col>
          <b-col md="4" lg="4" xl="3">
            <b-card class="w-100">
              <b-skeleton animation="throb" width="85%"></b-skeleton>
              <b-skeleton animation="throb" width="55%"></b-skeleton>
              <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-if="total > 0"
          v-model="pageNo"
          :total-rows="total"
          :per-page="pageSize"
          first-text="首页"
          prev-text="上一页"
          next-text="下一页"
          last-text="尾页"
          next-class="v-next-page-style"
          last-class="v-last-page-style"
          page-class="v-page-style"
          first-class="v-first-page-style"
          prev-class="v-prev-page-style"
          class="mb-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import SingleProduct from "@/components/child/SingleProduct";
import { get } from "@/utils";
export default {
  name: "Game",
  components: {
    SingleProduct,
  },
  data() {
    return {
      gameArticleList: null,
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    initArticle() {},
    initHomeArticleList(res) {
      if (res.code === 200) {
        this.gameArticleList = res.data.content;
        this.total = res.data.totalElements;
      }
    },
    executeRequest() {
      this.$store.state.loading = true;
      get("/article/homeList", {
        page: this.pageNo - 1,
        size: this.pageSize,
        type: 2,
      }).then((dataOne) => {
        this.$store.state.loading = false;
        this.initHomeArticleList(dataOne);
      });
    },
  },
  watch:{
    pageNo(newValue, oldValue){
      if(newValue != oldValue){
        this.executeRequest();
      }
    }
  },
  mounted() {},
  created() {
    this.executeRequest();
  },
};
</script>
<style scoped src="../css/Game.css"></style>
