<template>
  <b-container :fluid="$store.state.isFullWidth">
    <b-row>
      <b-col lg="2" md="2" cols="12"> </b-col>
      <b-col lg="10" md="10" cols="12">
        <div class="blog-detail">
          <!-- News meta -->
          <ul class="news-meta">
            <li><i class="fa fa-user"></i>{{diaryInfo.nickname}}</li>
            <li><i class="fa fa-pencil"></i>{{$moment(diaryInfo.createDate).format("YYYY年MM月DD日")}}</li>
          </ul>

          <div class="w-100 article-diary-fullText" v-html="fullText"></div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { get } from "@/utils";
export default {
  name: "DiaryDetail",
  data() {
    return {
      fullText: "",
      diaryId: this.$router.history.current.params.id,
      diaryInfo: null,
    };
  },
  methods: {
    initDiaryArticle() {
      get("/diary/info", { diaryId: this.diaryId }).then((res) => {
        if (res.code == 200) {
          this.diaryInfo = res.data;
          this.fullText = res.data.content;
        }
      });
    },
  },
  mounted() {
    this.initDiaryArticle();
  },
};
</script>
<style scoped  src="../css/DiaryDetail.css">

</style>