<template>
  <ul
    :class="isDefault?`slicknav_nav`:`${parentOpen?`sub-menu`:`sub-menu slicknav_hidden`}`"
    :aria-hidden="parentOpen"
    role="menu"
    :style="`${!parentOpen ? `display:none` : null}`"
  >
    <li v-for="(item,index) in menuList" v-bind:key="index">
    
      <router-link
      v-if="item.child && item.child.length > 0"
        :to="item.child[0].path" 
        class="slicknav_item slicknav_row"
        style="outline: none;"
        @click.native="openChildMenu(item)"
        >
        <a href="javascript:;" tabindex="0">{{item.label}}</a>
        <span class="slicknav_arrow" v-if="parentOpen">►</span>
        <span class="slicknav_arrow" v-else>||</span>
        </router-link>
        <router-link v-else :to="item.path"   style="outline: none;"  @click.native="openChildMenu(item)">{{item.label}}</router-link>
      <middle-child-menu v-if="item.child && item.child.length > 0"  :menuList="item.child" :isDefault="false" :parentOpen="item.open"/>
    </li>
  </ul>
</template>
<script>
export default {
  name: "MiddleChildMenu",
  props:{
      menuList:{
          type:Array,

      },
      isDefault:{
          type:Boolean,
          required: true
      },
      parentOpen:{
          type:Boolean,
           required: true
      }
  },
  methods:{
      openChildMenu(data){
          data.open = !data.open;
          if(data.child == null || data.child.length === 0){
            this.initParam();
          }
      },
      initParam(){
        this.$store.state.slicknavOpen = false;
      }
  },
  mounted(){
    this.initParam();
  }
};
</script>
<style scoped src="../../css/layout/MiddleChildMenu.css"></style>