<template>
  <ul class="comment-new" v-if="hotArticleList">

    <li class="comment-new-li text-truncate pt-1 pb-1" v-for="(item,index) in hotArticleList" v-bind:key="index">
      <!-- <b-icon icon="circle-fill" animation="throb" font-scale="4"></b-icon> -->
      <b-avatar square  size="4rem"  badge-left badge-variant="warning" class="mr-2" :src="`${$store.state.imgUrl}${item.homeImgUrl}`">
      </b-avatar>
      <router-link :to="`/detail/${item.articleId}`" class="card-link">{{item.title}}</router-link>
    </li>
   
  </ul>
</template>
<script>
export default {
    name:"NewRanking",
    props:{
      hotArticleList:{
        type:Array
      }
    }
}
</script>
<style scoped>
a {
    transition: .3s;
}
a {
    color: #000;
    text-decoration: none;
}
a {
    background-color: transparent;
}
li.comment-new-li {
  border-bottom: solid 1px #EAEAEA;
}

li a {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px;
}

li a:hover {
    color: #28a745;
}


</style>