import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    api: '/blog-manage',
    headerScroll:false,
    detailOperScroll:false,
    updateMsg:"功能正在紧急开发中，请您谅解！",
    loading:false,
    baseUrl:'http://blog.liuxingdong.com',
    imgUrl:'http://img.liuxingdong.com',
    template:'template-1',
    slicknavOpen:false,
    homeData:{
      pageNo:0,
      pageSize:2,
      total:0,
    },
    userInfo:null,
    clientWidth:0,
    isFullWidth:false,

  }
})
export default store;