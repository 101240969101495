<template>
  <div class="entry-share-buttons">
    <div class="ja-content-list-share ja-list-share">
      <ul>
        <li>
          <span class="ja-circle"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="13px"
              height="13px"
              viewBox="0 0 896 1024"
            >
              <path
                d="M704 640c-45.216 0-86.774 15.638-119.58 41.79l-204.972-128.108c2.894-12.527 4.553-26.911 4.553-41.683s-1.659-29.156-4.8-42.977l0.247 1.294 204.972-128.108c32.806 26.154 74.364 41.792 119.58 41.792 106.038 0 192-85.962 192-192s-85.962-192-192-192-192 85.962-192 192c0 14.316 1.58 28.26 4.552 41.682l-204.972 128.108c-32.806-26.152-74.364-41.79-119.58-41.79-106.038 0-192 85.962-192 192s85.962 192 192 192c45.216 0 86.774-15.638 119.58-41.79l204.972 128.108c-2.894 12.51-4.552 26.874-4.552 41.626 0 0.020 0 0.039 0 0.059v-0.003c0 106.038 85.962 192 192 192s192-85.962 192-192-85.962-192-192-192z"
              ></path></svg
          ></span>
        </li>
        <!--  <li class="ja-share-sina">
          <span
            class="ja-circle sina-share-btn"
            v-b-tooltip.hover
            title="Sina"
            @click="onShareFulltext('sina')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13px"
              height="13px"
              viewBox="0 0 896 1024"
            >
              <path
                d="M803.217 1.035c-22.733-3.788-41.677 11.367-41.677 30.31-3.788 18.944 11.367 41.677 30.31 45.466 71.987 15.155 212.17 37.887 246.27 250.058 3.788 22.732 18.943 45.465 45.465 37.887 18.943-3.789 34.098-15.155 26.521-64.409C1072.218 91.966 913.091 8.613 803.216 1.035m75.776 310.679c-7.578-37.888-45.465-83.353-90.93-87.142-15.155-3.789-26.522 7.578-30.31 18.944-3.79 15.155 3.788 30.31 18.943 34.099 30.31 7.577 45.465 26.521 49.254 49.254 0 0 3.789 15.155 18.944 18.943 15.155 3.79 41.676-7.577 34.099-34.098"
                fill="#333333"
                p-id="830"
              ></path>
              <path
                d="M985.077 323.08c-11.366-98.508-83.353-178.072-178.072-193.227-30.31-7.577-45.465 18.944-45.465 37.888s11.367 34.099 37.888 37.887c64.409 18.944 106.085 45.465 117.451 121.24 3.79 22.733 22.733 34.1 37.888 34.1 11.366 0 30.31-15.156 30.31-37.888zM788.062 714.989c-11.367 136.395-204.593 246.27-409.186 242.48C189.438 953.681 60.62 847.595 83.353 730.144c18.943-117.452 178.071-215.96 359.932-227.325 193.226-15.156 356.143 79.563 344.777 212.17m-30.31-219.748h-11.367c0-3.789 0-3.789 3.789-15.155 11.366-30.31 30.31-90.93-7.578-125.029-26.52-22.733-128.817-37.888-238.691 37.888-3.789 3.788-15.155 11.366-18.944 11.366-3.789 0-7.578-7.578-7.578-18.944-3.788-56.831 41.677-212.17-75.775-197.015C276.58 211.084 106.085 411.888 56.831 510.396c-170.494 348.566 75.776 492.539 318.256 496.327 265.213 7.578 530.426-143.973 564.525-348.565 26.521-136.396-132.607-162.917-181.86-162.917"
                fill="#333333"
                p-id="831"
              ></path>
              <path
                d="M420.552 743.632c0 11.366-11.366 18.944-18.944 18.944s-15.155-7.578-15.155-18.944 11.367-18.944 18.944-18.944c11.366 0 18.944 7.578 15.155 18.944m-30.31 49.254c-3.789 30.31-30.31 56.831-60.62 56.831s-49.254-22.732-45.465-56.831c3.789-30.31 30.31-56.832 56.831-56.832 30.31 0 53.043 22.733 49.254 56.832m11.366-193.227c-87.141 3.789-166.705 75.775-178.071 159.128-11.367 87.141 49.254 155.339 136.395 159.128 94.719 0 178.072-75.775 189.438-166.706 11.366-87.141-56.832-155.339-147.762-151.55"
                fill="#333333"
                p-id="832"
              ></path>
            </svg>
          </span>
        </li> -->
        <li class="ja-share-facebook">
          <span
            class="ja-circle facebook-share-btn"
            v-b-tooltip.hover
            title="FaceBook"
            @click="onShareFulltext('facebook')"
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="13px"
              height="13px"
              viewBox="0 0 602 1024"
            >
              <path
                d="M566.846 7.384v162.462h-96.615q-52.923 0-71.385 22.153t-18.462 66.461v116.308h180.308l-24 182.154h-156.308v467.077h-188.308v-467.077h-156.923v-182.154h156.923v-134.154q0-114.462 64.001-177.539t170.462-63.077q90.462 0 140.308 7.384z"
              ></path>
            </svg>
          </span>
        </li>
        <li class="ja-share-twitter">
          <span
            class="ja-circle twitter-share-btn"
            v-b-tooltip.hover
            title="Twitter"
            @click="onShareFulltext('twitter')"
          >
            <svg
              width="13px"
              height="13px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
              ></path>
            </svg>
          </span>
        </li>
        <li class="ja-share-qq">
          <span
            class="ja-circle qq-share-btn"
            v-b-tooltip.hover
            title="QQ"
            @click="onShareFulltext('qq')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13px"
              height="13px"
              viewBox="0 0 448 512"
            >
              <path
                d="M433.754 420.445c-11.526 1.393-44.86-52.741-44.86-52.741 0 31.345-16.136 72.247-51.051 101.786 16.842 5.192 54.843 19.167 45.803 34.421-7.316 12.343-125.51 7.881-159.632 4.037-34.122 3.844-152.316 8.306-159.632-4.037-9.045-15.25 28.918-29.214 45.783-34.415-34.92-29.539-51.059-70.445-51.059-101.792 0 0-33.334 54.134-44.859 52.741-5.37-.65-12.424-29.644 9.347-99.704 10.261-33.024 21.995-60.478 40.144-105.779C60.683 98.063 108.982.006 224 0c113.737.006 163.156 96.133 160.264 214.963 18.118 45.223 29.912 72.85 40.144 105.778 21.768 70.06 14.716 99.053 9.346 99.704z"
              ></path>
            </svg>
          </span>
        </li>
        <li class="ja-share-wechat" id="popover-wechat-1">
          <span
            class="ja-circle wechat-share-btn"
            @click="onShareFulltext('wechat')"
          >
            <svg
              t="1608335214637"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="8070"
              width="13px"
              height="13px"
            >
              <path
                d="M690.1 377.4c5.9 0 11.8 0.2 17.6 0.5-24.4-128.7-158.3-227.1-319.9-227.1C209 150.8 64 271.4 64 420.2c0 81.1 43.6 154.2 111.9 203.6 5.5 3.9 9.1 10.3 9.1 17.6 0 2.4-0.5 4.6-1.1 6.9-5.5 20.3-14.2 52.8-14.6 54.3-0.7 2.6-1.7 5.2-1.7 7.9 0 5.9 4.8 10.8 10.8 10.8 2.3 0 4.2-0.9 6.2-2l70.9-40.9c5.3-3.1 11-5 17.2-5 3.2 0 6.4 0.5 9.5 1.4 33.1 9.5 68.8 14.8 105.7 14.8 6 0 11.9-0.1 17.8-0.4-7.1-21-10.9-43.1-10.9-66 0-135.8 132.2-245.8 295.3-245.8z m-194.3-86.5c23.8 0 43.2 19.3 43.2 43.1s-19.3 43.1-43.2 43.1c-23.8 0-43.2-19.3-43.2-43.1s19.4-43.1 43.2-43.1z m-215.9 86.2c-23.8 0-43.2-19.3-43.2-43.1s19.3-43.1 43.2-43.1 43.2 19.3 43.2 43.1-19.4 43.1-43.2 43.1z"
                p-id="8071"
              ></path>
              <path
                d="M866.7 792.7c56.9-41.2 93.2-102 93.2-169.7 0-124-120.8-224.5-269.9-224.5-149 0-269.9 100.5-269.9 224.5S540.9 847.5 690 847.5c30.8 0 60.6-4.4 88.1-12.3 2.6-0.8 5.2-1.2 7.9-1.2 5.2 0 9.9 1.6 14.3 4.1l59.1 34c1.7 1 3.3 1.7 5.2 1.7 2.4 0 4.7-0.9 6.4-2.6 1.7-1.7 2.6-4 2.6-6.4 0-2.2-0.9-4.4-1.4-6.6-0.3-1.2-7.6-28.3-12.2-45.3-0.5-1.9-0.9-3.8-0.9-5.7 0.1-5.9 3.1-11.2 7.6-14.5zM600.2 587.2c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c0 19.8-16.2 35.9-36 35.9z m179.9 0c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c-0.1 19.8-16.2 35.9-36 35.9z"
                p-id="8072"
              ></path>
            </svg>
          </span>
        </li>
        <b-popover target="popover-wechat-1" triggers="hover" placement="top">
          <template #title>二维码</template>
          <div> <vue-qr :text="qrcodeurl" :margin="0" class="wechat-qcode" :logoScale="0.3" :size="150"></vue-qr></div>
        </b-popover>
      </ul>
    </div>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  name: "ShareFulltext",
  components:{
    vueQr
  },
  props: {
    articleInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      qq: "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey",
      sina: "http://v.t.sina.com.cn/share/share.php",
      weChat: "http://s.jiathis.com/qrcode.php",
      facebook: "https://www.facebook.com/sharer/sharer.php",
      twitter: "https://twitter.com/intent/tweet",
    };
  },
  computed:{
    qrcodeurl(){
      return `${this.$store.state.baseUrl}${this.$route.fullPath}`
    }
  },
  methods: {

    onShareFulltext(type) {
      const domain = this.$store.state.baseUrl;
      const share = `${this.articleInfo.title}\n ${domain}`;
      const link = encodeURIComponent(`${domain}${this.$route.fullPath}`);
      const appKey = "";
      const sinaParam = `url=${link}&title=${encodeURIComponent(
        this.articleInfo.title
      )}&content=${share}&pic=${encodeURIComponent(
        this.articleInfo.homeImgUrl
      )}&appkey=${appKey}`;
      const twitterParam = `url=${link}&text=${encodeURIComponent(share)}`;
      const facebookParam = `u=${link}`;
      const qqParam = `url=${link}&sharesource=qzone&title=${encodeURIComponent(
        this.articleInfo.title
      )}&pics=${encodeURIComponent(
        this.articleInfo.homeImgUrl
      )}&summary=${encodeURIComponent(share)}`;
      switch (type) {
        case "qq":
          window.open(`${this.qq}?${qqParam}`, "_blank");
          break;
        //  需要AppKey,填写身份证信息，弃用
        case "sina":
          window.open(`${this.sina}?${sinaParam}`, "_blank");
          break;
        case "wechat":
          // window.location.href = `${this.weChat}?url=${domain}${this.$route.fullPath}`;
          break;
        case "facebook":
          window.open(`${this.facebook}?${facebookParam}`, "_blank");
          break;
        case "twitter":
          window.open(`${this.twitter}?${twitterParam}`, "_blank");
          break;
        default:
          break;
      }

      /*   this.$bvToast.toast(this.$store.state.updateMsg, {
        title: "系统提示",
        variant: "info",
      });  */
    },
  },
  mounted(){
     
  },
};
</script>
<style scoped src="../../css/child/ShareFulltext.css"></style>
