<template>
  <section class="portfolio section-space">
    <b-container :fluid="$store.state.isFullWidth">
      <b-row>
        <b-col lg="6" offset-lg="3" md="6" offset-md="3">
          <div class="section-title default text-center">
            <div class="section-top">
              <h1><span>书籍</span><b>我的书单</b></h1>
            </div>
            <div class="section-bottom">
              <div class="text">
                <p>
                  下面是个人整理自工作以来，读过的各种书籍，有认为最好的给予分享出来，涵盖了技术与文学各大领域
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="portfolio-menu">
            <ul class="portfolio-nav tr-list list-inline cbp-l-filters-work">
              <li
                :class="`cbp-filter-item ${active == 0 ? `active` : ``}`"
                @click="onToggleBook(0)"
              >
                全部
              </li>
              <li
                :class="`cbp-filter-item ${active == 1 ? `active` : ``}`"
                @click="onToggleBook(1)"
              >
                前端技术书籍
              </li>
              <li
                :class="`cbp-filter-item ${active == 2 ? `active` : ``}`"
                @click="onToggleBook(2)"
              >
                后端技术书籍
              </li>
              <li
                :class="`cbp-filter-item ${active == 3 ? `active` : ``}`"
                @click="onToggleBook(3)"
              >
                文学书籍
              </li>
              <li
                :class="`cbp-filter-item ${active == 4 ? `active` : ``}`"
                @click="onToggleBook(4)"
              >
                历史书籍
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row v-if="bookList">
            <b-col v-for="(item, index) in bookList" v-bind:key="index">
              <b-card no-body style="max-width: 150px;">
                <b-card-body class="p-2">
                  <b-img
                    thumbnail
                    fluid-grow
                    v-bind="item.bookImgUrl?null:mainProps"
                    :src="`${$store.state.imgUrl}${item.bookImgUrl}`"
                    :alt="item.name"
                    style="height: 159px;width: 120px;"
                  ></b-img>
                </b-card-body>
                <b-card-footer class="p-1">
                  <h6 style="font-size:13px;text-align: left;" class="mb-1 text-truncate" v-b-tooltip.hover :title="item.name">
                    {{ item.name }}
                  </h6>
                  <span style="color: #555;font-size:13px;max-width: 120px;" class="d-inline-block text-truncate" v-b-tooltip.hover :title="item.author">{{
                    item.author
                  }}</span>
                </b-card-footer>
                <b-card-body class="p-1">
                  <b-form-rating
                    variant="warning"
                    :value="item.bookRating"
                    inline
                    disabled
                    size="sm"
                    class="mb-1 p-1 rounded-0 border-0"
                  ></b-form-rating
                  >
                  <b-card-text>
                    <span style="font-size:13px;" v-b-tooltip.hover title="阅读时间"
                      ><i class="fa fa-calendar pr-1" aria-hidden="true"></i>{{
                        $moment(item.readDate).format("YYYY年MM月DD日")
                      }}</span
                    >
                  </b-card-text>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="total">
        <b-col cols="12">
          <b-pagination
            v-if="total > 0"
            v-model="pageNo"
            :total-rows="total"
            :per-page="pageSize"
            first-text="首页"
            prev-text="上一页"
            next-text="下一页"
            last-text="尾页"
            next-class="v-next-page-style"
            last-class="v-last-page-style"
            page-class="v-page-style"
            first-class="v-first-page-style"
            prev-class="v-prev-page-style"
            class="mb-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import { get } from "@/utils";
export default {
  name: "Book",
  data() {
    return {
      active: 0,
      bookRating: 3,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 95,
        class: "m1",
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      bookType: 0,
      bookList: null,
    };
  },
  methods: {
    initBookList() {
      this.$store.state.loading = true;
      get("/book/list", {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        bookType: this.bookType,
      }).then((res) => {
        this.$store.state.loading = false;
        if (res.code === 200) {
          this.bookList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    onToggleBook(type) {
      this.bookType = type;
      this.active = type;
      this.initBookList();
    },
  },
  mounted() {
    this.initBookList();
  },
  watch: {
    pageNo(newValue, oldValue) {
      if (newValue != oldValue) {
        this.initBookList();
      }
    },
  },
};
</script>
<style scoped src="../css/Book.css"></style>
