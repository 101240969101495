<template>
  <div class="reply pt-4">
    <div class="reply-head">
      <h2 class="reply-title">进行评论</h2>
      <!-- Comment Form -->
      <form class="form" action="#">
        <b-row>
          <template v-if="$store.state.userInfo">
            <b-col cols="12" style="height:200px">
              <quill-editor
                v-model="content"
                ref="myQuillEditor"
                theme="snow"
                :options="smallScreenOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @change="onEditorChange($event)"
              >
              </quill-editor>
            </b-col>
            <b-col cols="12">
              <b-card
                class="text-center"
                style="height: 210px;overflow-y: auto;overflow-x: hidde;"
                v-if="commentUserList"
              >
                <b-list-group>
                  <b-list-group-item
                    class="d-flex align-items-center"
                    v-for="(item, index) in commentUserList"
                    v-bind:key="index"
                  >
                    <b-avatar class="mr-3" :src="item.avatarUrl"></b-avatar>
                    <span class="mr-auto">{{ item.nickname }}</span>
                    <b-badge>{{ item.total }}</b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-col>
            <b-col cols="12">
              <div class="form-group button pt-3">
                <button type="button" class="btn" @click="onSubmit">
                  发送
                </button>
              </div>
            </b-col>
          </template>
          <template v-else>
            <b-card class="text-center w-100 mr-2 ml-2">
              <b-card-text>
                <b-icon icon="lock-fill" class="mr-1"/>
                <b-link to="/login">登录</b-link>
                后参与评论</b-card-text
              >
            </b-card>
          </template>
        </b-row>
      </form>
    </div>
  </div>
</template>
<script>
import { post, get } from "@/utils";
import Bus from "@/utils/EventBus";
export default {
  name: "LeaveComment",
  props: {
    articleId: {
      type: String,
      require: true,
    },
    commentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      content: ``,
      replyParentId:null,
      replyParentName:null,
      editorOption: {
        placeholder:
          "请自觉遵守互联网相关的政策法规，严禁发布广告、色情、暴力、反动的言论。",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["clean"], //清除字体样式
            ["image"], //上传图片、上传视频
          ],
        },
      },
      smallScreenOption: {
        placeholder:
          "请自觉遵守互联网相关的政策法规，严禁发布广告、色情、暴力、反动的言论。",
        modules: {
          toolbar: [
            ["bold", "underline"], //加粗，斜体，下划线，删除线
            ["emoji"], //清除字体样式
            ["image"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
          ],
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": true,
        },
      },
      delta: {
        ops: [
          { retain: 1 },
          {
            insert: "@小马",
            attributes: { link: "http://wikipedia.org", bold: true },
          },
        ],
      },
      commentUserList: null,
    };
  },

  methods: {
    onEditorReady(editor) {
      // 准备编辑器
      console.log(editor);
    },
    onEditorBlur(event) {
      console.log(event);
    }, // 失去焦点事件
    onEditorFocus(event) {
      console.log(event);
    }, // 获得焦点事件
    onEditorChange(event) {
      // console.log(event);
      // console.log(event.quill);
      if (event.text.indexOf("@") === 0) {
        // event.quill.deleteText(0, 1);
        // this.getCommentuserList();
        // event.quill.updateContents(this.delta);
      }
    }, // 内容改变事件
    onSubmit() {
      this.busy = true;
      if (this.content) {
        post("/comment/save", {
          articleId: this.articleId,
          commentId: this.commentId,
          content: this.replyParentId ? this.content.replace(this.replyParentName,''):this.content,
          parentGid: this.replyParentId,
        }).then((res) => {
          this.busy = false;
          if (res.code == 200) {
            Bus.$emit("getCommentInfo", this.articleId);
            this.content = null;
            this.replyParentId = null;
            this.replyParentName = null;
          } else if (res.code == 2001) {
            this.$router.replace({ path: "/login" });
          } else {
            this.$bvToast.toast(res.msg, {
              title: "系统提示",
              variant: "warning",
            });
          }
        });
      } else {
        this.busy = false;
      }
    },
    getUserInfo() {
      this.userInfo = this.$cookies.get("liuxd-blog-userinfo");
    },
    getCommentuserList() {
      get("/comment/userList", { articleId: this.articleId }).then((res) => {
        if (res.code == 200) {
          this.commentUserList = res.data;
        }
      });
    },
    initUserReply(data) {
      if(!this.$store.state.userInfo){
         this.$bvToast.toast("请登陆", {
              title: "系统提示",
              variant: "warning",
            });
        return;
      }
      const text = this.editor.getText();
      console.log(data);
      if (text.indexOf(`@${data.author}`) !== 0 && text.indexOf("@") != 0) {
        this.replyParentName = `@${data.author}`;
        this.replyParentId = data.gid;
        this.editor.updateContents({
        ops: [
          {
            insert: `@${data.author}`,
            attributes: { link: "http://wikipedia.org", bold: true },
          },
          {
            insert: " ",
          },
        ],
      });
      }else {
        this.$bvToast.toast("请删除编辑框内原有回复人后，再次操作", {
              title: "系统提示",
              variant: "warning",
            });
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    Bus.$on("initUserReply", this.initUserReply);
  },
  beforeDestroy() {
    Bus.$off("initUserReply", this.initUserReply);
  },
  created() {
    this.getUserInfo();
  },
};
</script>
<style scoped>
img,
a,
input,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}
.reply .reply-title {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 30px;
  display: block;
  background: #fff;
  padding-left: 12px;
}
.reply .reply-title:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  height: 100%;
  width: 3px;
  background: #f7941d;
}
.reply form {
  padding: 20px;
  border: 1px solid #eee;
}
@media only screen and (max-width: 576px) {
  .reply form {
    padding-left: 2px;
    padding-right: 2px;
  }
}
.reply .form-group {
  margin-bottom: 20px;
}
.reply .form-group label {
  color: #333;
  position: relative;
}
.reply .form-group label span {
  color: #ff2c18;
  display: inline-block;
  position: absolute;
  right: -12px;
  top: 4px;
  font-size: 16px;
}
.reply .form-group input {
  width: 100%;
  height: 45px;
  line-height: 50px;
  padding: 0 20px;
  border-radius: 0px;
  color: #333 !important;
  border: none;
  border: 1px solid #eee;
}
.reply .form-group textarea {
  width: 100%;
  height: 200px;
  line-height: 50px;
  padding: 0 20px;
  border-radius: 0px;
  color: #333 !important;
  border: none;
  border: 1px solid #eee;
}
.btn {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  color: #fff !important;
  background: #333;
  display: inline-block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 5;
  display: inline-block;
  padding: 13px 32px;
  border-radius: 0px;
  text-transform: uppercase;
}
.reply .button {
  text-align: right;
  margin-bottom: 0px;
}
.reply .button .btn {
  height: 50px;
  border: none;
}
</style>
