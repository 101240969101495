<template>
  <div :class="`login ${$store.state.template}`">
    <b-container :fluid="$store.state.isFullWidth">
      <b-col cols="12">
        <b-row class="login-box">
          <b-col lg="5" md="12" sm="12" class="left-bg align-self-center">
            <div class="login-a">轻描、看花开</div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt Lorem Ipsum
            </p>
            <ul class="social-list clearfix">
              <li>
                <a href="javascript:;"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="javascript:;"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="javascript:;"><i class="fa fa-google-plus"></i></a>
              </li>
              <li>
                <a href="javascript:;"><i class="fa fa-linkedin"></i></a>
              </li>
            </ul>
          </b-col>
          <b-col lg="7" sm="12" class="p-0 align-self-center">
            <div class="login-inner-form">
              <div class="details">
                <h3>登录博客</h3>
                <form action="#">
                  <b-form-input v-model="form.username" v-anime="usernameAnime" @focus="onFouncDefault($event, 1)"
                    class="form-input" placeholder="账号/邮箱地址" required></b-form-input>
                  <b-form-input v-model="form.password" class="form-input" type="password" v-anime="passwordAnime"
                    @focus="onFouncDefault($event, 2)" placeholder="密码" required></b-form-input>
                  <div class="form-group form-input form-checkbox">
                    <b-row>
                      <b-col class="pr-1" cols="4">
                        <a href="javascript:;" @click="toggleVerCode" class="h-100">
                          <b-img-lazy :src="verCode" class="h-75"></b-img-lazy></a>
                      </b-col>
                      <b-col class="pl-0" cols="8">
                        <b-form-input v-model="form.verifyCode" class="form-input" placeholder="验证码"></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col align-self="start">
                      <b-form-checkbox v-model="form.rememberMe" name="checkbox-1" class="form-checkbox">
                        记住密码
                      </b-form-checkbox>
                    </b-col>
                    <b-col align-self="end" class="text-right">
                      <b-link href="javascript:;" :to="{ path: '/forgot/password' }">忘记密码？</b-link>
                    </b-col>
                  </b-row>


                  <div class="form-group">
                    <b-button block class="login-submit" :disabled="loginDisabled" @click="onSubmit">
                      登录
                      <b-overlay :show="loginDisabled" no-wrap></b-overlay>
                    </b-button>
                  </div>
                </form>
                <p>
                  没有账号?
                  <b-link href="javascript:;" @click="onRegister">去注册</b-link>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>
<script>
import { postConfig, get } from "@/utils";
export default {
  name: "Login",
  data() {
    return {
      identifyCodes: "1234567890",
      form: {
        username: null,
        password: null,
        verifyCode: null,
        rememberMe: false,
      },
      loginDisabled: false,
      verCode: null,
      current: null,
      usernameAnime: null,
      passwordAnime: null,
      submitAnime: null,

    };
  },
  methods: {
    onSubmit: async function () {
      this.loginDisabled = true;
      if (!this.form.username || !this.form.password || !this.form.verifyCode) {
        this.$bvToast.toast("请填写登录信息", {
          title: "系统提示",
          variant: "warning",
        });
      } else {
        postConfig(
          "/sys/login",
          {
            username: this.form.username,
            password: this.form.password,
            verifyCode: this.form.verifyCode,
            rememberMe: this.form.rememberMe,
          },
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.code === 200) {
            this.$cookies.remove("liuxd-blog-login");
            this.$cookies.set("liuxd-blog-login", true);
            this.getUserMenuInfo();

          } else {
            this.loginDisabled = false;
            this.toggleVerCode();
            this.$bvToast.toast(res.msg, {
              title: "系统提示",
              variant: "warning",
            });
          }
        });
      }
    },
    randomNum() {
      return Math.ceil(Math.random() * 10); // 生成一个随机数（防止缓存）;
    },
    getUserMenuInfo() {
      get('/menu/menuListForUser').then(res => {
        if (res.code == 200) {
          this.menuList = res.data;
          setTimeout(() => {
            this.$router.replace({ path: "/" });
          }, 1000);
        }
      })
    },
    toggleVerCode() {
      get("/user/defaultKaptcha").then((res) => {
        if (res.code === 200) {
          this.verCode = res.data.verImg;
        }
      });
    },
    onFouncDefault(e, type) {
      switch (type) {
        case 1:
          if (this.current) this.current.pause();
          this.usernameAnime = {
            rotate: "1turn",
            backgroundColor: "#FFF",
            duration: 2000,
            loop: true,
          };
          this.passwordAnime = null;
          this.submitAnime = null;
          break;

        case 2:
          if (this.current) this.current.pause();
          this.usernameAnime = null;
          this.passwordAnime = {
            rotate: "1turn",
            backgroundColor: "#FFF",
            duration: 2000,
            loop: true,
          };
          this.submitAnime = null;
          break;

        case 3:
          if (this.current) this.current.pause();
          this.usernameAnime = null;
          this.passwordAnime = null;
          this.submitAnime = {
            rotate: "1turn",
            backgroundColor: "#FFF",
            duration: 2000,
            loop: true,
          };
          break;
      }
    },
    getUserInfo() {
      get("/user/info").then((res) => {
        if (res.code === 200) {
          this.$store.state.userInfo = res.data;
          this.$cookies.remove("liuxd-blog-userinfo");
          this.$cookies.set("liuxd-blog-userinfo", res.data);
          this.meUserInfo = res.data;
        }
      });
    },
    onRegister() {
      this.$router.replace({ path: "/register" });
    }
  },
  beforeMount() {
    this.toggleVerCode();
  },
};
</script>
<style scoped src="../css/layout/Login.css">

</style>
