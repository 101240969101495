<template>
  <b-container :fluid="$store.state.isFullWidth">
     <b-row>
      <b-col sm="4">
        <div class="panel panel-smart">
          <div class="panel-heading">
            <h3 class="panel-title">个人信息</h3>
          </div>
          <div class="panel-body">
            <ul class="list-unstyled contact-details">
              <li class="clearfix">
                <i class="fa fa-user pull-left"></i>
                <span class="pull-left">
                  轻描、看花开 <br />
                  一个全栈的工程师，涉及到java后端、前端 <br />
                </span>
              </li>
              <li class="clearfix">
                <i class="fa fa-info-circle pull-left"></i>
                <span class="pull-left">
                  网站内如有侵犯您的权益、<br />
                  请提供相关信息，我们将会第一时间处理 <br />
                </span>
              </li>
              <li class="clearfix">
                <i class="fa fa-envelope-o pull-left"></i>
                <span class="pull-left"> liuxd1314@sina.com <br /> </span>
              </li>
            </ul>
          </div>
        </div>
      </b-col>
      <b-col sm="8">
        <div class="panel panel-smart">
          <div class="panel-heading">
            <h3 class="panel-title">发送信息</h3>
          </div>
          <div class="panel-body">
            <b-form class="form-horizontal" @submit.stop.prevent="onSubmit">
              <b-form-group
                label="您的称呼："
                label-for="message-name"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-input
                  id="message-name"
                  v-model="form.name"
                  type="text"
                  v-validate="{ required: true, min: 3 }"
                  :state="validateState('message-name')"
                  aria-describedby="message-name-feedback"
                  name="message-name"
                  data-vv-as="称呼"
                ></b-form-input>
                <b-form-invalid-feedback id="message-name-feedback">{{
                  veeErrors.first("message-name")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="邮件信息："
                label-for="message-email"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-input
                  id="message-email"
                  type="email"
                  v-model="form.email"
                  v-validate="{ required: true, min: 3,email:true }"
                  :state="validateState('message-email')"
                  aria-describedby="message-email-feedback"
                  name="message-email"
                  data-vv-as="邮件信息"
                ></b-form-input>
                <b-form-invalid-feedback id="message-email-feedback">{{
                  veeErrors.first("message-email")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="标题信息："
                label-for="message-title"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-input
                  id="message-title"
                  type="text"
                  v-model="form.title"
                  v-validate="{ required: true, min: 3 }"
                  :state="validateState('message-title')"
                  aria-describedby="message-title-feedback"
                  name="message-title"
                  data-vv-as="标题"
                ></b-form-input>
                <b-form-invalid-feedback id="message-title-feedback">{{
                  veeErrors.first("message-title")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="留言信息："
                label-for="message-content"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-textarea
                  id="message-content"
                  v-model="form.content"
                  v-validate="{ required: true, min: 3 }"
                  :state="validateState('message-content')"
                  aria-describedby="message-content-feedback"
                  name="message-content"
                  data-vv-as="留言信息"
                ></b-form-textarea>
                <b-form-invalid-feedback id="message-content-feedback">{{
                  veeErrors.first("message-content")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col class="d-flex justify-content-end">
                  <b-button type="submit" variant="primary">提交</b-button>
                  <b-button @click="resetForm()" variant="danger" class="ml-2"
                    >重置</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { post } from "@/utils";
export default {
  name: "Message",
  data() {
    return {
      form: {
        name: "",
        title: "",
        email: "",
        content: "",
      },
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.form = {
        name: "",
        title: "",
        email: "",
        content: "",
      };

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
		this.saveMessage();
      });
	},
	saveMessage(){
		post("/message/save",this.form).then(res=>{
			if(res.code === 200){
			this.$bvToast.toast(res.msg, {
              title: "系统提示",
              variant:'success'
            });
			}
		})
	}
  },
};
</script>
<style scoped src="../css/Message.css"></style>
