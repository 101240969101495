<template>
  <div
    class="cbp-item business animation"
    style="width: 360px; left: 0px; top: 0px;"
  >
    <div class="cbp-item-wrapper">
      <div class="single-portfolio">
        <div class="portfolio-head overlay">
            <!-- https://picsum.photos/600/417 -->
          <img :src="`${$store.state.imgUrl}${article.homeImgUrl}`" alt="#" />
          <router-link class="more" :to="`/detail/${article.articleId}`"
            ><i class="fa fa-long-arrow-right"></i
          ></router-link>
        </div>
        <div class="portfolio-content">
          <h4><router-link :to="`/detail/${article.articleId}`">{{article.title}}</router-link></h4>
          <p>时间：{{$moment(article.createDate).format("YYYY年MM月DD日")}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardList",
  props: {
    article: {
      type: Object,
      require: true,
    },
  },
};
</script>
<style scoped>
.cbp * {
  box-sizing: border-box;
}
.cbp-item-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.cbp-item-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.cbp-item:hover .cbp-l-grid-agency-title {
  color: #222;
}
.single-portfolio {
  position: relative;
  background: #fff;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.single-portfolio .portfolio-head {
  position: relative;
  z-index: 66;
}
.overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #2e2751;
  content: "";
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1;
}
.single-portfolio .portfolio-head.overlay::before {
  opacity: 0;
  visibility: hidden;
}
.cbp img {
  display: block;
  border: 0;
  width: 100%;
  height: auto;
}
.single-portfolio img {
  display: block;
  width: 100%;
  z-index: 3;
  transition: all 0.8s ease;
}
.cbp a,
.cbp a:hover,
.cbp a:active {
  text-decoration: none;
  outline: 0;
}
.single-portfolio .more {
  height: 50px;
  width: 50px;
  opacity: 0;
  line-height: 51px;
  text-align: center;
  color: #fff;
  background: #f3a712;
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  bottom: 0;
  font-size: 17px;
  border-radius: 100%;
  box-shadow: 0px 5px 5px #00000024;
  z-index: 333;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  visibility: hidden;
}
.single-portfolio .portfolio-content {
  position: relative;
  top: -30px;
  text-align: left;
  width: 78%;
  padding: 18px 20px;
  border-left: 3px solid #179e66;
  background: #fff;
  left: 15px;
  display: inline-block;
  z-index: 22222;
  margin-bottom: -30px;
  -webkit-box-shadow: -2px 2px 6px #0000002e;
  -moz-box-shadow: -2px 2px 6px #0000002e;
  box-shadow: -2px 2px 6px #0000002e;
}
.single-portfolio .portfolio-content h4 {
  line-height: 24px;
  font-size: 18px;
}
.single-portfolio .portfolio-content p {
  font-size: 14px;
}
p {
  color: #666;
  margin: 0;
  line-height: 24px;
}
.cbp a,
.cbp a:hover,
.cbp a:active {
  text-decoration: none;
  outline: 0;
}
.single-portfolio .portfolio-content h4 a {
  font-size: 18px;
  color: #2e2751;
  text-transform: capitalize;
}
.single-portfolio .more:hover {
  background: #fff;
  color: #f3a712;
}
.single-portfolio:hover .more {
  opacity: 1;
  visibility: visible;
}
.single-portfolio:hover .portfolio-head.overlay::before {
  opacity: 0.8;
  visibility: visible;
}
.single-portfolio .portfolio-content.hover {
  position: absolute;
  bottom: 0;
  padding: 15px 20px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 333;
  top: 0;
}
.single-portfolio:hover .portfolio-content.hover {
  opacity: 1;
  visibility: visible;
}
.single-portfolio .portfolio-content.hover h4 a {
  color: #fff !important;
}
.single-portfolio .portfolio-content.hover p {
  color: #eee;
}
.single-portfolio .portfolio-content .zoom {
  height: 70px;
  width: 70px;
  line-height: 68px;
  text-align: center;
  font-size: 20px;
  color: #179e66;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -35px;
  display: block;
  z-index: 34;
  background: #fff;
  border-radius: 50px;
  border: 2px solid transparent;
}
.single-portfolio .portfolio-content .zoom:hover {
  border-color: #fff;
  color: #fff;
  background-color: transparent;
}
</style>
