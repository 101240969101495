<template>
   <section class="blog-layout blog-latest section-space">
     <side-tools />
     <transition :name="transitionName">
      <router-view/>
     </transition>
   </section>
</template>
<script>
import SideTools from "@/components/layout/SideTools";
export default {
  name: "Content",
  components:{
    SideTools,
  },
  data() {
    return {
      transitionName:'fade'
    };
  },
  methods: {},
  mounted() {}
};
</script>
<style scoped src="../../css/layout/Content.css">
</style>