<template>
  <ul class="posts-list-items">
    <li v-for="(item,index) in newArticleList" v-bind:key="index">
      <div class="ja-post-outer">
        <div class="ja-post-inner ja-post-thumbnail">
            <router-link :to="`/detail/${item.articleId}`" class="post-thumb">
         <b-img-lazy
              v-bind="mainProps"
              :src="`${$store.state.imgUrl}${item.homeImgUrl}`"
              class="attachment-harmuny-widget-small size-harmuny-widget-small lazy-img wp-post-image"
              alt=""
          /></router-link>
          <span :class="{
            'post-count':(index + 1) <= 3,
            'post-count-other':(index + 1) > 3,
            }">{{`${index + 1}`}}</span>
        </div>
        <div class="ja-post-inner ja-post-data">
          <h4 class="entry-title">
            <router-link :to="`/detail/${item.articleId}`">{{item.title}}
            </router-link>
          </h4>
          <ul class="post-meta">
            <li>{{$moment(item.createDate).format("YYYY年MM月DD日")}}</li>
          </ul>
        </div>
      </div>
    </li>
   
  </ul>
</template>
<script>
export default {
  name: "NewPosts",
  props: {
    newArticleList: {
      type: Array,
    }
  },
  data(){
      return {
          mainProps: {
          blank: true,
          blankColor: '#bbb',
          width: 90,
          height: 90,
        }
      }
  }
};
</script>
<style scoped>
ul.posts-list-items {
    padding-top: 10px;
}
ul.posts-list-items li {
    padding-top: 10px;
    padding-bottom: 10px;
}
ul.posts-list-items li:first-child {
    padding-top: 0;
}
ul.posts-list-items li .ja-post-outer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
ul.posts-list-items li .ja-post-thumbnail {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    max-height: 90px;
    margin-right: 20px;
}
ul.posts-list-items li .ja-post-thumbnail a {
    display: block;
    position: relative;
    border-radius: 100%;
    -webkit-transition: .2s;
    transition: .2s;
}
img {
    border-style: none;
}
img {
    border: 0 none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
ul.posts-list-items li .ja-post-thumbnail a img {
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit:cover;';
    -webkit-transition: .2s;
    transition: .2s;
    position: relative;
    height: 60px;
}

ul li .post-count {
    background-color: #bfa279;
    position: absolute;
    top: -12px;
    left: -12px;
    width: 24px;
    height: 24px;
    font-size: 11px;
    border-radius: 50%;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    text-align: center;
    -webkit-transition: background .2s;
    transition: background .2s;
    z-index: 1;
}
ul li .post-count-other {
 background-color: #ccc;
    position: absolute;
    top: -12px;
    left: -12px;
    width: 24px;
    height: 24px;
    font-size: 11px;
    border-radius: 50%;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    text-align: center;
    -webkit-transition: background .2s;
    transition: background .2s;
    z-index: 1;
}
ul.posts-list-items li .ja-post-data {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
    font-size: 11px;
    line-height: 17px;
    color: #666;
}
ul.posts-list-items li .entry-title {
    margin-bottom: 5px;
    position: relative;
    top: 0;
}
ul.posts-list-items li .entry-title a {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px;
}
ul.posts-list-items li .entry-title a:hover {
    color: #28a745;
}
@media (min-width: 768px) {
    ul.posts-list-items li .entry-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: initial;
    text-transform: none;
}
}
a {
    background-color: transparent;
}
a {
    color: #000;
    text-decoration: none;
}
a {
    -moz-transition: .3s;
    -o-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s;
}


</style>