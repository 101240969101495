<template>
  <b-container :fluid="$store.state.isFullWidth">
    <b-row>
      <b-col md="3">
        <b-row>
          <b-col cols="12">
            <div class="panel panel-smart personal">
              <div class="panel-heading">
                <h3 class="panel-title">个人信息</h3>
              </div>
              <div class="panel-body">
                <div
                  :class="
                    `d-flex justify-content-center ${
                      showHeadPortrait ? 'hover-head' : ''
                    }`
                  "
                >
                  <b-img
                    class="head-portrait"
                    @mouseover="mouseOverHeadPortrait"
                    @mouseleave="mouseLeaveHeadPortrait"
                    thumbnail
                    v-bind="viewUserInfo.headPortraitStr ? null : mainProps"
                    fluid
                    :src="
                      `${$store.state.imgUrl}/${viewUserInfo.headPortraitStr}`
                    "
                    alt="头像"
                  ></b-img>
                  <div
                    class="text-center"
                    @mouseover="mouseOverHeadPortrait"
                    @mouseleave="mouseLeaveHeadPortrait"
                  >
                    <a
                      class="ibx-uc-ulink"
                      href="javascript:;"
                      @click="toggleUploadShow"
                      >更换头像</a
                    >
                  </div>
                </div>
                <img-upload
                  field="file"
                  @crop-success="cropSuccess"
                  @crop-upload-success="cropUploadSuccess"
                  @crop-upload-fail="cropUploadFail"
                  v-model="head.show"
                  :width="90"
                  :height="90"
                  :url="
                    `${$store.state.baseUrl}${$store.state.api}/user/uploadHeadPortrait`
                  "
                  :params="head.params"
                  :headers="head.headers"
                  img-format="png"
                ></img-upload>
                <ul class="list-unstyled contact-details">
                  <li class="clearfix">
                    <i class="fa fa-user pull-left fa-fw"></i>
                    <span class="pull-left">
                      昵称：{{ viewUserInfo.nickname }}
                    </span>
                  </li>
                  <li class="clearfix">
                    <i class="fa fa-id-card-o pull-left fa-fw"></i>
                    <span class="pull-left">
                      简介：{{ viewUserInfo.synopsis }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <!-- Categories Links Starts -->
            <h3 class="side-heading">个人设置</h3>
            <b-list-group class="categories rou rounded-0">
              <b-list-group-item
                href="javascript:;"
                @click="toggleTab('账号设置', 'account')"
              >
                <i class="fa fa-chevron-right"></i>
                账号设置
              </b-list-group-item>
              <b-list-group-item
                href="javascript:;"
                @click="toggleTab('我的收藏', 'collect')"
              >
                <i class="fa fa-chevron-right"></i>
                我的收藏
              </b-list-group-item>
              <b-list-group-item
                href="javascript:;"
                @click="toggleTab('我的评论', 'comment')"
              >
                <i class="fa fa-chevron-right"></i>
                我的评论
              </b-list-group-item>
            </b-list-group>
            <!-- Categories Links Ends -->
          </b-col>
        </b-row>
      </b-col>
      <b-col md="9">
        <div class="panel panel-smart">
          <div class="panel-heading">
            <h3 class="panel-title">{{ tabTitle }}</h3>
          </div>
          <div class="panel-body">
            <b-form
              @submit.stop.prevent="onSubmit"
              class="form-horizontal"
              role="form"
              v-if="tabSettingKey === 'account'"
            >
              <b-form-group
                label="昵称："
                label-for="message-name"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-input
                  v-model="accountForm.nickname"
                  name="nested-nickname"
                  v-validate="{ required: true, min: 1 }"
                  :state="validateState('nested-nickname')"
                  aria-describedby="input-nickname-feedback"
                  data-vv-as="昵称"
                ></b-form-input>
                <b-form-invalid-feedback id="input-nickname-feedback">{{
                  veeErrors.first("nested-nickname")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="账号："
                label-for="message-username"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-input
                  v-model="accountForm.username"
                  name="nested-username"
                  v-validate="{ required: true, min: 1 }"
                  :state="validateState('nested-username')"
                  aria-describedby="input-username-feedback"
                  data-vv-as="账号"
                ></b-form-input>
                <b-form-invalid-feedback id="input-username-feedback">{{
                  veeErrors.first("nested-username")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="密码："
                label-for="message-password"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-input
                  v-model="accountForm.password"
                  name="nested-password"
                  v-validate="{ required: true, min: 1 }"
                  :state="validateState('nested-password')"
                  aria-describedby="input-password-feedback"
                  data-vv-as="密码"
                ></b-form-input>
                <b-form-invalid-feedback id="input-password-feedback">{{
                  veeErrors.first("nested-password")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="邮箱："
                label-for="message-email"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-input
                  v-model="accountForm.email"
                  type="email"
                  name="nested-email"
                  v-validate="{ required: true, min: 1 }"
                  :state="validateState('nested-email')"
                  aria-describedby="input-email-feedback"
                  data-vv-as="邮箱"
                ></b-form-input>
                <b-form-invalid-feedback id="input-email-feedback">{{
                  veeErrors.first("nested-email")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="简介："
                label-for="message-synopsis"
                label-cols-sm="2"
                label-align-sm="right"
              >
                <b-form-textarea
                  v-model="accountForm.synopsis"
                  name="nested-synopsis"
                  v-validate="{ required: true, min: 1 }"
                  :state="validateState('nested-synopsis')"
                  aria-describedby="input-synopsis-feedback"
                  data-vv-as="简介"
                ></b-form-textarea>
                <b-form-invalid-feedback id="input-synopsis-feedback">{{
                  veeErrors.first("nested-synopsis")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <div class="form-group account-setting-submit">
                <div class="col-sm-12">
                  <b-button type="submit" squared variant="primary"
                    >提交</b-button
                  >
                </div>
              </div>
            </b-form>
            <b-row v-else-if="tabSettingKey === 'collect'">
              <b-col cols="4" class="p-0">
                <b-card text-variant="white" no-body class="rounded-0">
                  <b-list-group flush>
                    <b-list-group-item
                      href="javascript:;"
                      v-b-modal.modal-create-collect-folder
                      ><b-icon
                        icon="folder-plus"
                        aria-hidden="true"
                        class="mr-2"
                      ></b-icon
                      >新建文件夹</b-list-group-item
                    >
                    <template v-if="collectFolderList">
                      <b-list-group-item
                        href="javascript:;"
                        v-for="(item, index) in collectFolderList"
                        v-bind:key="index"
                        @click="openFolderCollect(item.collectFolderId)"
                        >{{ item.folderName }}</b-list-group-item
                      >
                    </template>
                  </b-list-group>
                </b-card>
              </b-col>
              <b-col cols="8">
                <b-row>
                  <b-col cols="12" class="p-0">
                    <b-card no-body class="rounded-0">
                      <b-card-text>
                        <b-row>
                          <b-col lg="12" xl="6" xs="12" sm="12">
                            <b-input-group>
                              <b-form-input
                                type="text"
                                class="rounded-0"
                              ></b-form-input>

                              <b-input-group-append>
                                <b-button squared variant="primary"
                                  >修改</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                          <b-col lg="12" xl="6" xs="12" sm="12">
                            <p class="collection-edit-box">
                              <a class="cursor line pl-2" href="javascript:void(0);" @click="onDelete"
                                >删除收藏夹</a>
                              <b-link class="cursor line pl-2" to="/batchMove"
                                >批量移动</b-link
                              >
                              <b-link class="cursor pl-2" to="/sessing"
                                >设为私密</b-link
                              >
                            </p>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col cols="12" class="p-0">
                    <b-card no-body class="rounded-0"> </b-card>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-create-collect-folder"
      ref="modal"
      title="创建收藏文件夹"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      button-size="sm"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="文件夹名称"
          label-for="name-input"
          invalid-feedback="文件夹名称不能为空"
          :state="collect.folderState"
        >
          <b-form-input
            id="name-input"
            v-model="collect.folderName"
            :state="collect.folderState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          取消
        </b-button>
        <b-button size="sm" variant="primary" @click="ok()"> 确定 </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import ImgUpload from "vue-image-crop-upload";
import Bus from "@/utils/EventBus";
import { get, post, put } from "@/utils";
export default {
  name: "Personal",
  components: {
    ImgUpload,
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      accountForm: {
        userId: null,
        synopsis: null,
        username: null,
        nickname: null,
        password: null,
        email: null,
      },
      tabTitle: "账号设置",
      tabSettingKey: "account",
      head: {
        show: false,
        params: null,
        headers: {
          smail: "*_~",
        },
        imgDataUrl: "https://picsum.photos/80/80/?image=54", // the datebase64 url of created image
      },
      viewUserInfo: {
        userId: null,
        synopsis: null,
        nickname: null,
        headPortraitStr: null,
      },
      showHeadPortrait: false,
      collect: {
        folderName: "",
        folderState: null,
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      collectFolderList: null,
      collectArticleList: null,
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        if (this.viewUserInfo.userId) {
          this.accountForm.userId = this.viewUserInfo.userId;
          put("/user/update", this.accountForm).then((res) => {
            if (res.code == 200) {
              this.$bvToast.toast("更新成功", {
                title: "系统提示",
                variant: "success",
              });
            } else if (res.code == 2001) {
              this.$router.replace({ path: "/login" });
            }
          });
        }
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      post("/collection/addFolder", {
        folderName: this.collect.folderName,
      }).then((res) => {
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("modal-create-collect-folder");
        });
        if (res.code === 200) {
          this.initCollectFolderInfo();
          this.$bvToast.toast("添加成功", {
            title: "系统提示",
            variant: "success",
          });
        }
      });
    },
    openFolderCollect(folderId) {
      if (folderId) {
        get("/collection/pageList", {
          pageNo: this.collect.pageNo,
          pageSize: this.collect.pageSize,
          folderId: folderId,
        }).then((res) => {
          if (res.code === 200) {
            this.collectArticleList = res.data.list;
            this.collect.total = res.data.total;
          }
        });
      }
    },
    toggleTab(title, key) {
      this.tabTitle = title;
      this.tabSettingKey = key;
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      console.log(field);
      this.head.imgDataUrl = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      Bus.$emit("navUserInfo");
      this.initUserInfo();
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    initUserInfo() {
      get("/user/info").then((res) => {
        if (res.code === 200) {
          this.accountForm = res.data;
          this.accountForm.password = null;
          this.viewUserInfo.userId = res.data.userId;
          this.viewUserInfo.synopsis = res.data.synopsis;
          this.viewUserInfo.nickname = res.data.nickname;
          this.viewUserInfo.headPortraitStr = res.data.headPortraitStr;
        } else if (res.code == 2001) {
          this.$router.replace({ path: "/login" });
        }
      });
    },
    toggleUploadShow() {
      this.head.show = true;
    },
    mouseOverHeadPortrait() {
      this.showHeadPortrait = true;
    },
    mouseLeaveHeadPortrait() {
      this.showHeadPortrait = false;
    },
    initCollectFolderInfo() {
      get("/collection/folderList").then((res) => {
        if (res.code === 200) {
          this.collectFolderList = res.data;
        }
      });
    },
  },
  watch: {
    tabSettingKey(newValue) {
      switch (newValue) {
        case "collect":
          this.initCollectFolderInfo();
          break;

        default:
          break;
      }
    },
  },
  created() {
    this.initUserInfo();
  },
};
</script>
<style scoped src="../css/Personal.css"></style>
