<template>
  <b-row
    cols="1"
    :class="$store.state.detailOperScroll ? 'oper-left-item' : null"
    style="width:220px"
  >
    <b-col>
      <ul class="sidebar-list">
        <li class="search-item">
          <span
            class="algolia-autocomplete"
            style="position: relative; display: inline-block; direction: ltr;"
            ><input
              type="text"
              class="ds-input"
              autocomplete="off"
              spellcheck="false"
              role="combobox"
              aria-autocomplete="list"
              aria-expanded="false"
              aria-label="搜索输入"
              aria-owns="algolia-autocomplete-listbox-0"
              dir="auto"
              style="position: relative; vertical-align: top;"/>
            <pre
              aria-hidden="true"
              style='position: absolute; visibility: hidden; white-space: pre; font-family: "Sofia Pro", sans-serif; font-size: 18px; font-style: normal; font-variant: normal; font-weight: 400; word-spacing: 0px; letter-spacing: normal; text-indent: 0px; text-rendering: auto; text-transform: none;'
            ></pre>
            <span
              class="ds-dropdown-menu"
              role="listbox"
              id="algolia-autocomplete-listbox-0"
              style="position: absolute; top: 100%; z-index: 100; display: none; left: 0px; right: auto;"
            >
              <div class="ds-dataset-1"></div> </span
          ></span>
        </li>
      </ul>
    </b-col>
    <b-col>
      <div class="item-info">
        <div class="item-one">
          <span
            ><a href="javascript:void(0);" @click="onClickLike" rel="noopener"
              ><div class="item-two" v-b-tooltip.hover title="鼓掌">
                <svg width="29" height="29" aria-label="clap">
                  <g fill-rule="evenodd">
                    <path
                      d="M13.74 1l.76 2.97.76-2.97zM16.82 4.78l1.84-2.56-1.43-.47zM10.38 2.22l1.84 2.56-.41-3.03zM22.38 22.62a5.11 5.11 0 0 1-3.16 1.61l.49-.45c2.88-2.89 3.45-5.98 1.69-9.21l-1.1-1.94-.96-2.02c-.31-.67-.23-1.18.25-1.55a.84.84 0 0 1 .66-.16c.34.05.66.28.88.6l2.85 5.02c1.18 1.97 1.38 5.12-1.6 8.1M9.1 22.1l-5.02-5.02a1 1 0 0 1 .7-1.7 1 1 0 0 1 .72.3l2.6 2.6a.44.44 0 0 0 .63-.62L6.1 15.04l-1.75-1.75a1 1 0 1 1 1.41-1.41l4.15 4.15a.44.44 0 0 0 .63 0 .44.44 0 0 0 0-.62L6.4 11.26l-1.18-1.18a1 1 0 0 1 0-1.4 1.02 1.02 0 0 1 1.41 0l1.18 1.16L11.96 14a.44.44 0 0 0 .62 0 .44.44 0 0 0 0-.63L8.43 9.22a.99.99 0 0 1-.3-.7.99.99 0 0 1 .3-.7 1 1 0 0 1 1.41 0l7 6.98a.44.44 0 0 0 .7-.5l-1.35-2.85c-.31-.68-.23-1.19.25-1.56a.85.85 0 0 1 .66-.16c.34.06.66.28.88.6L20.63 15c1.57 2.88 1.07 5.54-1.55 8.16a5.62 5.62 0 0 1-5.06 1.65 9.35 9.35 0 0 1-4.93-2.72zM13 6.98l2.56 2.56c-.5.6-.56 1.41-.15 2.28l.26.56-4.25-4.25a.98.98 0 0 1-.12-.45 1 1 0 0 1 .29-.7 1.02 1.02 0 0 1 1.41 0zm8.89 2.06c-.38-.56-.9-.92-1.49-1.01a1.74 1.74 0 0 0-1.34.33c-.38.29-.61.65-.71 1.06a2.1 2.1 0 0 0-1.1-.56 1.78 1.78 0 0 0-.99.13l-2.64-2.64a1.88 1.88 0 0 0-2.65 0 1.86 1.86 0 0 0-.48.85 1.89 1.89 0 0 0-2.67-.01 1.87 1.87 0 0 0-.5.9c-.76-.75-2-.75-2.7-.04a1.88 1.88 0 0 0 0 2.66c-.3.12-.61.29-.87.55a1.88 1.88 0 0 0 0 2.66l.62.62a1.88 1.88 0 0 0-.9 3.16l5.01 5.02c1.6 1.6 3.52 2.64 5.4 2.96a7.16 7.16 0 0 0 1.18.1c1.03 0 2-.25 2.9-.7A5.9 5.9 0 0 0 23 23.24c3.34-3.34 3.08-6.93 1.74-9.17l-2.87-5.04z"
                    ></path>
                  </g>
                </svg></div></a
          ></span>
        </div>
        <div class="item-three">
          <div class="item-four">
            <h4 class="item-five">
              <button class="item-six">
                {{ likes }}
              </button>
            </h4>
          </div>
        </div>
      </div>
      <div class="commeng-item-one">
        <button class="commeng-item-two" @click="goAuchor('#comment-area')">
          <div class="commeng-item-three" v-b-tooltip.hover title="点击评论">
            <svg width="25" height="25" class="r" aria-label="responses">
              <path
                d="M19.07 21.12a6.33 6.33 0 0 1-3.53-1.1 7.8 7.8 0 0 1-.7-.52c-.77.21-1.57.32-2.38.32-4.67 0-8.46-3.5-8.46-7.8C4 7.7 7.79 4.2 12.46 4.2c4.66 0 8.46 3.5 8.46 7.8 0 2.06-.85 3.99-2.4 5.45a6.28 6.28 0 0 0 1.14 2.59c.15.21.17.48.06.7a.69.69 0 0 1-.62.38h-.03zm0-1v.5l.03-.5h-.03zm-3.92-1.64l.21.2a6.09 6.09 0 0 0 3.24 1.54 7.14 7.14 0 0 1-.83-1.84 5.15 5.15 0 0 1-.16-.75 2.4 2.4 0 0 1-.02-.29v-.23l.18-.15a6.6 6.6 0 0 0 2.3-4.96c0-3.82-3.4-6.93-7.6-6.93-4.19 0-7.6 3.11-7.6 6.93 0 3.83 3.41 6.94 7.6 6.94.83 0 1.64-.12 2.41-.35l.28-.08z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <div class="commeng-item-four">
              <h4 class="commeng-item-five">{{ commentCount }}</h4>
            </div>
          </div>
        </button>
      </div>
      <div class="collect-item">
        <span v-b-tooltip.hover title="进行收藏"
          ><a
            href="javascript:void(0);"
            v-b-modal.modal-collect-folder
            rel="noopener"
            ><svg width="25" height="25" viewBox="0 0 25 25">
              <path
                d="M19 6a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v14.66h.01c.01.1.05.2.12.28a.5.5 0 0 0 .7.03l5.67-4.12 5.66 4.13a.5.5 0 0 0 .71-.03.5.5 0 0 0 .12-.29H19V6zm-6.84 9.97L7 19.64V6a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v13.64l-5.16-3.67a.49.49 0 0 0-.68 0z"
                fill-rule="evenodd"
              ></path></svg></a
        ></span>
      </div>
    </b-col>
    <b-modal
      id="modal-collect-folder"
      ref="modal"
      title="收藏文章"
      ok-title="确定"
      cancel-title="取消"
      @show="resetModal"
      @ok="collectClick"
      @hidden="resetModal"
    >
      <b-form-group :label="inputType ==1 ?'选择文件夹':'增加新的收藏文件夹'">
        <b-input-group>
          <template v-if="inputType == 1">
            <b-form-select
              v-model="selected"
              :options="options"
              text-field="folderName"
              value-field="collectFolderId"
            ></b-form-select>
            <b-input-group-append>
              <b-button variant="outline-primary" @click="addCollectList(2)"
                >增加</b-button
              >
            </b-input-group-append>
          </template>
          <template v-else>
            <b-form-input v-model="addCollectText"></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-primary" @click="addCollectList(1)"
                >选择</b-button
              >
            </b-input-group-append>
          </template>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </b-row>
</template>
<script>
import { get, put, post } from "@/utils";
export default {
  name: "LeftOper",
  props: {
    articleId: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      commentCount: 0,
      likes: 0,
      selected: null,
      addCollectText: "",
      defaultFolder: {
        collectFolderId: null,
        collectFolderName: null,
      },
      options: [],
      inputType: 1,
    };
  },
  methods: {
    resetModal() {
      this.options = [];
      get("/collection/folderList").then((res) => {
        if (res.code == 200 && res.data) {
          this.options = res.data;
          this.selected = res.data[0];
        } else if (res.code == 2001) {
          this.$router.push({ path: "/login" });
        }
      });
    },
    addCollectList(type) {
      this.inputType = type;
    },
    collectClick() {
      if(this.inputType == 1 && this.selected){
        post('/collection/add',{folderId :this.selected, articleId: this.articleId}).then(res=>{
          if(res.code == 200){
            this.$bvToast.toast("添加成功", {
            title: "系统提示",
            variant: "success",
          });
          }
        })
      }else if(this.inputType == 2 && this.addCollectText){
        post('/collection/addFolder',{ folderName: this.addCollectText}).then(res=>{
          if(res.code == 200){
            this.$bvToast.toast("添加成功", {
            title: "系统提示",
            variant: "success",
          });
          }
        })
      }
    },
    goAuchor(id) {
      document.querySelector(id).scrollIntoView(true);
      const auchor = this.$el.querySelector(id);
      document.body.scrollTop = auchor.offsetTop;
    },
    onClickLike() {
      put("/article/opearData", { articleId: this.articleId }).then((res) => {
        if (res.code == 200) {
          this.initData();
          this.$bvToast.toast(res.msg, {
            title: "系统提示",
            variant: "success",
          });
        }
      });
    },
    initData() {
      get("/article/opearInfo", { articleId: this.articleId }).then((res) => {
        if (res.code == 200) {
          this.commentCount = res.data.commentCount;
          this.likes = res.data.likes;
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
  beforeDestroy() {},
};
</script>
<style scoped>
ul {
  list-style: none;
}
.sidebar-list li {
  margin-bottom: 0.3em;
}
.sidebar-list .search-item {
  display: none;
  text-transform: none;
}
@media (min-width: 768px) {
  .sidebar-list .search-item {
    display: block;
  }
}
.sidebar-list > li {
  margin-bottom: 0.5em;
}
.oper-left-item {
  position: fixed;
  top: 110px;
}
.sidebar-list .search-item {
  background-size: 24px;
  background-position: 4px 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAZlBMVEVMaXEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACgxKPNAAAAInRSTlMAJhIGCCMlIQEHDxYTHB4JFwsRFQMdHw0OGSIFJBsMEBgUaXHvbQAAAflJREFUeNrtWNl2gyAQhYDiUo1GjUbT7f9/siZHUBorssw5act9dTI3zHIZBnl4eHj8ApCkyFgQhgHLioQgx4irHkvoqxi5w1uOV5CXyA0od/9IQZE9ohRvII1s/ZMz3sTZMt3HK1YgONr4L0OsRFha/H/JP0vbuI6iOm5TJjEYn4Es4/NBpcq6LKNkmIdokd/X4YE9W2TarJbSOQjd2vdkDmBq1F9zCOIfLAJhYqIbuTrERDDkBvoj4hNvnFJEqTQ/QLdllXCrRlufRf1s22WmWaj4DwdFr3C7SpOg5/2lMuQd13//sPOPUaWccEuddp6Tx9SmXJcSLYKCt6hT05XiaNWm3WSaaRGw/cVHRTR1wDWgVpvWXFG0CLgERDtUnUsKNAGGDtH1uZJsUKYNdKMVzyUV4GIn5PqyV66Z6YVDgC4c8Cvzfumrk/dufOmjUowtFGZs0Ru8GrvRkYKMjsvhN1mN/zz8flqP7xnZGt9zhapvh5h33FHqX+kBMjh6QhUdvT2haFcwLKEFfQROeDE8Q6B8xgoGoIf40AoGiFVCNdbPHCXny5Dm3oHWeRhz3ay6n/THPtNrCyk2L6QOThhuK7X0tlLDV9ZMKzXXDDI8gw3DAUHAM3gGV/hjDCcEggOw/5EB2D9CJ2D/IwOw/5EB2L+Hh4fHP8YXhrcT5ad0MfAAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  border-bottom: 1px solid #9a9dae;
  font-size: 1.5rem;
  margin-bottom: 1em;
  margin-right: 2em;
  padding-left: 1.5em;
}
pre {
  margin-bottom: 2.5rem;
}
button,
input {
  overflow: visible;
}
.sidebar-list .search-item input {
  background: transparent;
  border: none;
  margin: 0;
  outline: none;
  padding: 0 0 0 4px;
}
*,
*:before,
*:after {
  box-sizing: inherit !important;
}
/* 喜欢的节点信息 */
.item-info {
  align-items: center;
  display: flex;
}
.item-one {
  margin-right: 5px;
  position: relative;
  display: block;
}
.item-three {
  margin-top: 5px;
  display: block;
}
.item-five {
  color: rgba(117, 117, 117, 1);
  line-height: 20px;
  font-size: 14px;
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd,
ol,
ul,
menu,
figure,
blockquote,
p,
pre,
form {
  margin: 0;
}
a,
button,
input {
  -webkit-tap-highlight-color: transparent;
}
.item-six {
  background: transparent;
  overflow: visible;
  font-size: inherit;
  border: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  color: inherit;
  fill: inherit;
  text-align: left;
  outline: none;
}
/* 评论区 */
.commeng-item-one {
  margin-bottom: 19px;
  display: block;
}
.commeng-item-two {
  background: transparent;
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
}
.commeng-item-three {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 9px;
}
.commeng-item-four {
  display: block;
  position: relative;
  padding-left: 6px;
}
.commeng-item-five {
  font-weight: 400;
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  /* color: rgba(117, 117, 117, 1); */
}
</style>
