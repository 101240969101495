<template>
  <b-container :fluid="$store.state.isFullWidth">
    <b-row>
      <b-col lg="8" xs="12" sm="12" md="8" xl="8" class="left-plan">
        <b-row>
          <Carousel />
        </b-row>
        <b-row>
          <template v-if="homeArticleList">
            <template v-for="(item, index) in homeArticleList">
              <b-card
                no-body
                class="overflow-hidden p-3 mt-3 article-card-list"
                @click="onClickViewDetail(item.articleId)"
                v-bind:key="`card-${index}`"
              >
                <b-row>
                  <b-col md="3">
                    <b-img-lazy
                      @click.native="clickPrew($event)"
                      thumbnail
                      fluid
                      :src="`${$store.state.imgUrl}${item.homeImgUrl}`"
                      :alt="item.title"
                    ></b-img-lazy>
                  </b-col>
                  <b-col md="9">
                    <b-card-body class="pt-0 pb-0 pr-0 horizontal-item">
                      <router-link
                        :to="`/detail/${item.articleId}`"
                        class="card-link"
                      >
                        <b-card-title title-tag="h3" class="text-truncate"
                          >{{ item.title }}
                        </b-card-title>
                      </router-link>
                      <b-card-text class="text-break">
                        {{ item.fullText }}
                      </b-card-text>
                    </b-card-body>
                    <b-card-body
                      class="pt-2 pb-0 pr-0 tools"
                      style="font-size: 8px"
                    >
                      <span
                        v-b-tooltip.hover
                        :title="item.tags?item.tags.join('、'):'无'"
                        style="border-right: 1px solid #d6d6d6"
                        class="pr-1"
                      >
                        <i class="fa fa-tags pr-1" aria-hidden="true"></i>{{item.tags?item.tags.length:0}}
                      </span>
                      <span
                        style="border-right: 1px solid #d6d6d6"
                        class="pr-1"
                        v-b-tooltip.hover
                        title="时间：2020年11月06号"
                      >
                        <i
                          class="fa fa-calendar pr-1 pl-2"
                          aria-hidden="true"
                        ></i
                        >{{ $moment(item.createDate).format("YYYY年MM月DD日") }}
                      </span>
                      <span
                        style="border-right: 1px solid #d6d6d6"
                        class="pr-1"
                        v-b-tooltip.hover
                        :title="`评论 (${item.commentCount})`"
                      >
                        <i
                          class="fa fa-comments pr-1 pl-2"
                          aria-hidden="true"
                        ></i
                        >{{ item.commentCount }}
                      </span>
                      <span class="pr-1">
                        <a href="javascript:;" class="card-link pl-2 auther"
                          >作者：{{ item.author }}</a
                        >
                      </span>
                      <span
                        class="float-right pr-2"
                        v-b-tooltip.hover
                        :title="`阅读量 (${item.readCount})`"
                        ><i class="fa fa-eye pr-1 pl-2" aria-hidden="true"></i
                        >{{ item.readCount }}</span
                      >
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </template>
          <template v-else>
            <b-card class="overflow-hidden p-3 mt-3 w-100">
              <b-skeleton animation="throb" width="85%"></b-skeleton>
              <b-skeleton animation="throb" width="55%"></b-skeleton>
              <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
          </template>
        </b-row>
      </b-col>
      <b-col class="side-right pr-0" lg="4" md="4" xl="4">
        <div class="single-sidebar bizwheel_latest_news_widget">
          <h2 class="sidebar-title">点击排行</h2>
          <template v-if="hotArticleList">
            <new-ranking :hotArticleList="hotArticleList" />
          </template>
          <template v-else>
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </template>
        </div>
  <div class="single-sidebar bizwheel_latest_news_widget">
     <Music/>
  </div>


        <div class="single-sidebar bizwheel_latest_news_widget">
          <h2 class="sidebar-title">最近更新</h2>
          <template v-if="newArticleList">
            <new-posts :newArticleList="newArticleList" />
          </template>
          <template v-else>
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </template>
        </div>
        <div class="single-sidebar bizwheel_latest_news_widget">
          <h2 class="sidebar-title">最新评论</h2>
          <template v-if="newCommentList">
            <template v-for="(item, index) in newCommentList">
              <single-item
                :newArticle="item"
                :index="index"
                v-bind:key="index"
              />
            </template>
          </template>
          <template v-else>
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </template>
        </div>
        
      </b-col>
      <div class="mod-side-bar">
        <a href="javascript:;" class="p-1" v-b-toggle.sidebar-backdrop
          ><i class="fa fa-list fa-2x" aria-hidden="true"></i
        ></a>
      </div>
      <b-sidebar id="sidebar-backdrop" backdrop-variant="dark" backdrop shadow>
        <b-col cols="12" class="w-100">
          <div class="single-sidebar bizwheel_latest_news_widget">
            <h2 class="sidebar-title">点击排行</h2>
            <template v-if="hotArticleList">
              <new-ranking :hotArticleList="hotArticleList" />
            </template>
            <template v-else>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
            </template>
          </div>
          <div class="single-sidebar bizwheel_latest_news_widget">
            <h2 class="sidebar-title">最近更新</h2>
            <template v-if="newArticleList">
              <new-posts :newArticleList="newArticleList" />
            </template>
            <template v-else>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
            </template>
          </div>
          <div class="single-sidebar bizwheel_latest_news_widget">
            <h2 class="sidebar-title">最新评论</h2>
            <template v-if="newCommentList">
              <template v-for="(item, index) in newCommentList">
                <single-item
                  :newArticle="item"
                  :index="index"
                  v-bind:key="index"
                />
              </template>
            </template>
            <template v-else>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
            </template>
          </div>
          
        </b-col>
      </b-sidebar>
    </b-row>
    <b-row>
      <b-col cols="12">
      <b-pagination
        v-if="total > 0"
        v-model="pageNo"
        :total-rows="total"
        :per-page="pageSize"
        first-text="首页"
        prev-text="上一页"
        next-text="下一页"
        last-text="尾页"
        next-class="v-next-page-style"
        last-class="v-last-page-style"
        page-class="v-page-style"
        first-class="v-first-page-style"
        prev-class="v-prev-page-style"
        class="mb-0"
      ></b-pagination>
      </b-col>
    </b-row>
   
  </b-container>
</template>
<script>
// import fancyBox from 'vue-fancybox';
import Carousel from "@/components/layout/Carousel";
import SingleItem from "@/components/child/SingleItem";
import NewPosts from "@/components/child/NewPosts";
import NewRanking from "@/components/child/NewRanking";
// import Bus from "@/utils/EventBus";
import { get } from "@/utils";
import Music from './tools/Music';
export default {
  name: "Home",
  components: {
    NewRanking,
    NewPosts,
    SingleItem,
    Carousel,
    Music,
  },
  data() {
    return {
      loading: true,
      newArticleList: null,
      newCommentList: null,
      hotArticleList: null,
      homeArticleList: null,
      pageNo: 1,
      pageSize: 6,
      total: 0,
      imgList: [],
    };
  },
  methods: {
    onClickViewDetail(articleId) {
      this.$router.push({ path: `/detail/${articleId}` }).catch((err) => err);
    },
    clickPrew(e) {
      console.log("===================>>");
      console.log(this.imgList);
      console.log(e);
      // fancyBox(e.target, this.imgList);
    },
    initLoad() {
      this.loading = true;
    },
    initNewArticle(res) {
      if (res.code === 200) {
        this.newArticleList = res.data;
      }
    },
    initNewComment(res) {
      if (res.code === 200) {
        this.newCommentList = res.data;
      }
    },
    initChickArticleList(res) {
      if (res.code === 200) {
        this.hotArticleList = res.data;
      }
    },
    initHomeArticleList(res) {
      if (res.code === 200) {
        this.homeArticleList = res.data;
        this.pageInfo.total = res.data ? res.data.length : 0;
        this.initImgList();
      }
    },
    initUserInfo(res) {
      if (res.code === 200) {
        this.$store.state.userInfo = res.data;
      }
    },
    executeRequest() {
      this.$store.state.loading = true;
      this.pageInfo.pageNo = this.$store.state.homeData.pageNo;
      this.pageInfo.pageSize = this.$store.state.homeData.pageSize;
      this.$axios
        .all([
          this.$axios.get("/article/homeList", {
            params: {
              page: this.pageInfo.pageNo - 1,
              size: this.pageInfo.pageSize,
              type: 1,
            },
          }),
          this.$axios.get("/article/newArticleList"),
          this.$axios.get("/article/newCommentList"),
          this.$axios.get("/article/hotArticleList"),
        ])
        .then(
          this.$axios.spread((dataOne, dataTwo, dataThree, dataFour) => {
            this.$store.state.loading = false;
            this.initHomeArticleList(dataOne.data);
            this.initNewArticle(dataTwo.data);
            this.initNewComment(dataThree.data);
            this.initChickArticleList(dataFour.data);
            // this.initUserInfo(dataFive.data);
          })
        );
    },
    initImgList() {
      for (const item in this.homeArticleList) {
        const imgObj = {
          width: 601,
          height: 1024,
          url: this.homeArticleList[item].homeImgUrl,
        };
        this.imgList.push(imgObj);
      }
    },
    initNewArticleList() {
      get("/article/newArticleList").then((res) => {
        if (res.code === 200) {
          this.newArticleList = res.data;
        }
      });
    },
    initMainHomeList() {
      get("/article/homeList", {
        page: this.pageNo - 1,
        size: this.pageSize,
        type: 0,
      }).then((res) => {
        if (res.code === 200) {
          this.homeArticleList = res.data.content;
          this.total = res.data.totalElements;
          this.initImgList();
        }
      });
    },
    initMainNewCommentList() {
      get("/article/newCommentList").then((res) => {
        if (res.code === 200) {
          this.newCommentList = res.data;
        }
      });
    },
    initMainHotArticleList() {
      get("/article/hotArticleList").then((res) => {
        if (res.code === 200) {
          this.hotArticleList = res.data;
        }
      });
    },
  },
  mounted() {
    this.initLoad();
    this.initMainHomeList();
    this.initNewArticleList();
    this.initMainNewCommentList();
    this.initMainHotArticleList();
  },
  created() {
    // this.executeRequest();
  },
  watch: {
    pageNo(newValue, oldValue) {
      if (newValue != oldValue) {
        this.initMainHomeList();
      }
    },
  },
};
</script>
<style scoped src="../css/Home.css"></style>
