<template>
    <div :class="`login ${$store.state.template}`">
        <b-container :fluid="$store.state.isFullWidth">
            <b-col cols="12">
                <b-row class="login-box">
                    <b-col lg="5" md="12" sm="12" class="left-bg align-self-center">
                        <div class="login-a">轻描、看花开</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                            eiusmod tempor incididunt Lorem Ipsum
                        </p>
                        <ul class="social-list clearfix">
                            <li>
                                <a href="javascript:;"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="javascript:;"><i class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a href="javascript:;"><i class="fa fa-google-plus"></i></a>
                            </li>
                            <li>
                                <a href="javascript:;"><i class="fa fa-linkedin"></i></a>
                            </li>
                        </ul>
                    </b-col>
                    <b-col lg="7" sm="12" class="p-0 align-self-center">
                        <div class="login-inner-form">
                            <div class="details">
                                <h3>找回密码</h3>
                                <b-form @submit.stop.prevent="onSubmit">
                                    <b-form-group label-for="nested-email">
                                        <b-form-input id="nested-email" v-model="updatePassword.email"
                                            placeholder="邮件地址" name="nested-email"
                                            v-validate="{ required: true, min: 1 }"
                                            :state="validateState('nested-email')"
                                            aria-describedby="input-email-feedback" data-vv-as="邮件地址"></b-form-input>
                                        <b-form-invalid-feedback id="input-email-feedback">{{
        veeErrors.first("nested-email")
}}</b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-group label-for="nested-verif-code">
                                        <b-form-input id="nested-verif-code" v-model="updatePassword.verifCode"
                                            placeholder="验证码" name="nested-verif-code"
                                            v-validate="{ required: true, min: 1 }"
                                            :state="validateState('nested-verif-code')"
                                            aria-describedby="input-verif-code-feedback"
                                            data-vv-as="验证码"></b-form-input>
                                        <b-form-invalid-feedback id="input-verif-code-feedback">{{
        veeErrors.first("nested-verif-code")
}}</b-form-invalid-feedback>
                                    </b-form-group>
                                    <div class="form-group">
                                        <b-button block class="login-submit" :disabled="nextDisabled" @click="onSubmit">
                                            下一步
                                        </b-button>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-container>
    </div>
</template>
<script>
export default {
    name: "ForgotPassword",
    data() {
        return {
            nextDisabled: false,
            updatePassword: {
                email: null,
                verifCode: null
            }
        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        onSubmit() {
        }
    },
}
</script>
<style scoped src="../css/ForgotPassword.css">

</style>