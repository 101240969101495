<template>
  <div class="entry-tags-bottom" v-if="tagsList">
    <span class="ja-meta-tags meta-item">
      <template v-for="(item,index) in tagsList">
        <a href="javascript:void(0);" rel="tag" @click="onClickTags(item)" v-bind:key="index">{{item}}</a>
      </template>
    </span>
  </div>
</template>
<script>
export default {
    name:"TagList",
    props:{
      tagsList:{
        type:Array

      }
    },
    data(){
      return {

      }
    },
    methods:{
      onClickTags(data){
        console.log(data);
      }
    }
}
</script>
<style scoped>
.entry-tags-bottom {
    position: relative;
    overflow: hidden;
    clear: both;
    text-align: center;
}
.entry-tags-bottom span {
    display: inline-block;
    float: none;
}
svg:not(:root) {
    overflow: hidden;
}

.entry-tags-bottom .ja-meta-tags>svg {
    display: none;
}
.entry-tags-bottom span a {
    display: inline-block;
    padding: 4px 12px;
    margin: 5px 5px 0 0;
    background: #f0f0f0;
    color: #646464;
    border-radius: 2px;
}
</style>