<template>
  <b-overlay :show="$store.state.loading" rounded="sm" class="tts">
    <Header />
    <Content />
    <Footer />
  </b-overlay>
</template>
<script>
import Header from "@/components/layout/Header";
import Content from "@/components/layout/Content";
import Footer from "@/components/layout/Footer";
export default {
  name: "MLayout",
  components: {
    Header,
    Content,
    Footer,
  },
  methods: {
  },
};
</script>