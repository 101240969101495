<template>
  <div :class="`middle-header ${$store.state.headerScroll?`animated bounceInDown top`:``}`" id="middle-header">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="middle-inner">
            <b-row>
              <b-col lg="2" md="3" cols="12" class="align-text-bottom">
                <!-- Logo -->
                <div class="logo">
                  <!-- Image Logo -->
                  <div class="img-logo">
                    <a href="javascript:;">
                      <img :src="require('@/assets/new.png')" alt="#" style="height:38.14px;width:165px;"/>
                      <!-- 轻描、看花开 -->
                    </a>
                  </div>
                </div>

                <div class="mobile-nav">
                  <div class="slicknav_menu">
                    <a
                      href="javascript:;"
                      aria-haspopup="true"
                      role="button"
                      tabindex="0"
                      :class="`slicknav_btn ${$store.state.slicknavOpen?`slicknav_open`:null}`"
                      style="outline: none;"
                      @click="toggleNav"
                      ><span class="slicknav_menutxt"></span
                      ><span class="slicknav_icon slicknav_no-text"
                        ><span class="slicknav_icon-bar"></span
                        ><span class="slicknav_icon-bar"></span
                        ><span class="slicknav_icon-bar"></span></span
                    ></a>
                    <middle-child-menu :menuList="menuList" :isDefault="true" :parentOpen="$store.state.slicknavOpen"/>
                  </div>
                </div>
              </b-col>
              <b-col lg="10" md="9" cols="12">
                <div class="menu-area">
                  <nav class="navbar navbar-expand-lg">
                    <div class="navbar-collapse">
                      <div class="nav-inner">
                        <div class="menu-home-menu-container">
                          <!-- Naviagiton -->
                          <ul id="nav" class="nav main-menu menu navbar-nav">
                            <li
                              :class="
                                item.child && item.child.length > 0
                                  ? `icon-active`
                                  : null
                              "
                              v-bind:key="index"
                              v-for="(item, index) in menuList"
                            >
                              <router-link :to="item.child && item.child.length > 0?item.child[0].path:item.path">{{
                                item.label
                              }}</router-link>
                              <ChildMenu
                                :menuList="item.child"
                                v-if="item.child && item.child.length > 0"
                              />
                            </li>
                          </ul>
                          <!--/ End Naviagiton -->
                        </div>
                      </div>
                    </div>
                  </nav>

                  <!-- Right Bar -->
                  <div class="right-bar">
                    <!-- Search Bar -->
                    <ul class="right-nav">
                      <li class="top-search" @click="clickOpenSearch">
                        <a href="javascript:;">
                          <i class="fa fa-search"></i>
                        </a>
                      </li>
                      <li class="bar">
                        <a href="javascript:;">
                          <i class="fa fa-bars"></i>
                        </a>
                      </li>
                    </ul>
                    <!--/ End Search Bar -->
                    <!-- Search Form -->
                    <div
                      :class="searchFlag ? `search-top active` : `search-top`"
                    >
                      <b-form
                        class="search-form"
                      >
                        <b-form-input
                        v-model="searchText"
                          placeholder="检索关键词..."
                          ref="searchText"
                        ></b-form-input>
                        <button type="button" @click="onClickSearch">
                          <i class="fa fa-search"></i>
                        </button>
                      </b-form>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ChildMenu from "@/components/child/ChildMenu";
import MiddleChildMenu from "@/components/child/MiddleChildMenu";
export default {
  name: "MiddleHeader",
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    ChildMenu,
    MiddleChildMenu,
  },
  data() {
    return {
      searchFlag: false,
      slicknavOpen:false,
      searchText:'',
    };
  },
  methods: {
    clickOpenSearch() {
      this.searchFlag = !this.searchFlag;
      if(this.searchFlag){
        this.$nextTick(() => {
          this.$refs['searchText'].focus();
        });
      }
    },
    onClickSearch() {
       this.$bvToast.toast(this.$store.state.updateMsg, {
              title: "系统提示",
              variant: "warning",
            });
    },
    onReset() {},
    toggleNav(){
      this.$store.state.slicknavOpen = !this.$store.state.slicknavOpen;
    }
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../css/layout/MiddleHeader.css"></style>
