<template>
    <div class="task-bg">
        <div class="task-sign-in">
			<div class="task-sign-info">
				<div class="task-score">
					<span>+5</span>
					<span>+10</span>
					<span>+15</span>
					<span class="text-yellow">+20</span>
					<span>+25</span>
					<span>+30</span>
					<span class="text-yellow">+35</span>
				</div>
				<div class="task-num sign2 sign3">
					<span class="active">1</span>
					<span class="active">2</span>
					<span class="active now">3</span>
					<span>4</span>
					<span>5</span>
					<span>6</span>
					<span>7</span>
				</div>
				<div class="task-date">
					<span>第1天</span>
					<span>第2天</span>
					<span>第3天</span>
					<span>第4天</span>
					<span>第5天</span>
					<span>第6天</span>
					<span>第7天</span>
				</div>
			</div>
			<div class="task-sign-btn">
				<b-button block variant="primary" size="lg">签到</b-button>
			</div>
		</div>
    </div>
</template>
<script>
export default {
    name:"Task",
    data(){
        return {

        }
    },
    methods:{
        initTaskDate(){
           let ss =  this.$moment().subtract(7, 'days');
           console.log(ss);
           let ss1 = ss.calendar();
           let ss2 = ss.day();
           console.log(ss1);
           console.log(ss2);
        }
    },
    mounted(){
        this.initTaskDate();
    }
}
</script>
<style scoped>
.task-bg {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    background: url(../assets/task/bg.jpg) top center no-repeat #FFFFFF;
    background-size: 100%;
}
.task-sign-in {
    margin: 10rem 0.66rem 0 0.66rem;
    position: relative;
}
.task-sign-in .task-sign-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.task-sign-in .task-sign-info>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.task-sign-btn {
    padding: 1.5rem 1.5rem 0 1.5rem;
}
.task-sign-in .task-sign-info .task-num {
    font-size: 0.16rem;
    color: #289ef4;
    margin-top: 1.3rem;
    margin-bottom: 1.35rem;
    position: relative;
    height: 0.4rem;
    padding: 0 1.55rem;
}


.task-sign-in .task-sign-info>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.text-yellow {
    color: #ffa33e!important;
}
.task-sign-in .task-sign-info .task-date {
    font-size: 0.24rem;
    color: #289ef4;
    padding: 0 1.4rem;
}
.task-sign-in .task-sign-info .task-num span {
    display: inline-block;
    width: 1.1rem;
    height: 1.1rem;
    line-height: 1.1rem;
    text-align: center;
    background: #e4efff;
    border-radius: 50%;
    position: relative;
    z-index: 5;
}
.task-sign-in .task-sign-info .task-num span.active {
    background: #0068ff;
    color: #FFFFFF;
}
.task-sign-in .task-sign-info .task-num span.active.now:after {
    content: "";
    display: block;
    position: absolute;
    width: 1.3rem;
    height: 2rem;
    background: url(../assets/task/ok.png) center center no-repeat;
    background-size: 100%;
    bottom: -0.02rem;
}

.task-sign-in .task-sign-info>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.task-sign-in .task-sign-info .task-score {
    font-size: 0.18rem;
    color: #c2d1e9;
    margin-top: 1.6rem;
    padding: 0 1.55rem;
}
.task-sign-btn button {
    color: #FFFFFF !important;
}

</style>