<template>
  <section class="news-area archive blog-single section-padding">
    <b-container :fluid="$store.state.isFullWidth">
      <b-row>
        <b-col lg="2" md="2" cols="12" class="detail-left">
          <left-oper :articleId="articleId"/>
        </b-col>
        <b-col lg="10" md="10" cols="12">
          <b-row>
            <b-col cols="12">
              <div class="blog-single-main" v-if="articleInfo">
                <div class="main-image" v-if="articleInfo.homeImgBase64">
                  <img :src="item.homeImgUrl" alt="#" />
                </div>
                <!-- <b-card class="details-content"> -->
                <h2 class="blog-title">{{ articleInfo.title }}</h2>
                <!-- News meta -->
                <ul class="news-meta">
                  <li>
                    <i class="fa fa-pencil"></i
                    >{{
                      $moment(articleInfo.createDate).format("YYYY年MM月DD日")
                    }}
                  </li>
                  <li>
                    <i class="fa fa-comments"></i>({{
                      articleInfo.commentCount
                    }}) 评论
                  </li>
                  <li>
                    <i class="fa fa-eye" aria-hidden="true"></i>({{
                      articleInfo.readCount
                    }}) 阅读量
                  </li>
                </ul>

                <div
                  class="w-100 article-fullText"
                  v-html="articleInfo.fullText"
                ></div>
              
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="12">
              <tag-list :tagsList="tagsList" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <share-fulltext :articleInfo="articleInfo" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <posts-nav
                :prevNextList="prevNextList"
                :articleInfo="articleInfo"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <synopsis :userInfo="userInfo" />
            </b-col>
          </b-row>
          <!--    <b-row>
            <b-col cols="12">
              <comments-input :articleId="articleId" :commentId="commentId" />
              <comment-input-two
                :userInfo="meUserInfo"
                :articleId="articleId"
              />
            </b-col>
          </b-row> -->
          <b-row id="comment-area">
            <b-col cols="12">
              <blog-comment-two
                v-if="articleInfo"
                :userInfo="meUserInfo"
                :articleId="articleId"
                :articleUserId="articleInfo.createUserId"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <leave-comment :articleId="articleId" :userInfo="meUserInfo" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import Synopsis from "@/components/layout/Synopsis";
import BlogCommentTwo from "@/components/child/BlogCommentTwo";
import LeftOper from "@/components/child/LeftOper";
import TagList from "@/components/child/TagList";
import ShareFulltext from "@/components/child/ShareFulltext";
import PostsNav from "@/components/child/PostsNav";
import Bus from "@/utils/EventBus";
import { get } from "@/utils";
// import CommentInputTwo from "@/components/child/CommentInputTwo.vue";
import LeaveComment from "@/components/child/LeaveComment";
export default {
  name: "Detail",
  metaInfo() {
    return {
      title: this.articleInfo.title,
      description: "Liuxd 个人博客",
      keywords: "",
    };
  },
  components: {
    Synopsis,
    TagList,
    ShareFulltext,
    LeftOper,
    BlogCommentTwo,
    PostsNav,
    // CommentInputTwo,
    LeaveComment,
  },
  data() {
    return {
      form: {
        email: null,
        username: null,
        name: null,
        text: null,
      },
      userInfo: {
        userId: null,
        author: null,
        synopsis: null,
        headPortraitImg: null,
      },
      articleId: this.$router.history.current.params.id,
      commentId: null,
      articleInfo: null,
      meUserInfo: null,
      prevNextList: null,
      tagsList: null,
    };
  },
  methods: {
    initArticlePrevNext() {
      get("/article/prevNext", { articleId: this.articleId }).then((res) => {
        if (res.code === 200) {
          this.prevNextList = res.data;
        }
      });
    },
    initFulltext() {
      this.$store.state.loading = true;
      if (this.articleId) {
        get("/article/detail", { articleId: this.articleId }).then((res) => {
          this.$store.state.loading = false;
          if (res.code === 200) {
            this.tagsList = res.data.tags;
            this.articleInfo = res.data;
            this.articleInfo.fulltext = this.escapeStringHTML(
              res.data.fulltext
            );
            this.userInfo.userId = res.data.createUserId;
            this.userInfo.author = res.data.author;
            this.userInfo.synopsis = res.data.synopsis;
            this.userInfo.headPortraitImg = res.data.headPortraitImg;
          }
        });
      }
    },
    escapeStringHTML(str) {
      if (str) {
        str = str.replace(/&lt;/g, "<");
        str = str.replace(/&gt;/g, ">");
      }
      return str;
    },
    handCopy(){
      return false;
    },
    getUserInfo() {
      // this.meUserInfo = Bus.$emit('getUserInfo');
      if (this.$store.state.userInfo) {
        this.meUserInfo = this.$store.state.userInfo;
      } else {
        Bus.$emit("navUserInfo");
        this.meUserInfo = this.$store.state.userInfo;
      }
    },
    refreshFulltext(articleId) {
      if (this.articleId != articleId) {
        this.articleId = articleId;
        // this.$router.replace({path:`/detail/${articleId}`})
        this.initFulltext();
      }
    },
    onProhibitCopy(){
      document.oncontextmenu =new Function("event.returnValue=false");
      document.onselectstart =new Function("event.returnValue=false");
    }
  },
  created() {
    this.initFulltext();
    this.initArticlePrevNext();
    this.onProhibitCopy();
  },
  mounted() {
    this.getUserInfo();
    Bus.$on("refreshFulltext", this.refreshFulltext);
  },
  beforeDestroy() {
    Bus.$off("refreshFulltext", this.refreshFulltext);
  },
};
</script>
<style scoped src="../css/Details.css"></style>
