<template>
  <div :class="`login ${$store.state.template}`">
    <b-container :fluid="$store.state.isFullWidth">
      <b-col cols="12">
        <b-row class="login-box">
          <b-col lg="5" md="12" sm="12" class="left-bg align-self-center">
            <div class="login-a">轻描、看花开</div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt Lorem Ipsum
            </p>
            <ul class="social-list clearfix">
              <li>
                <a href="javascript:;"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="javascript:;"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="javascript:;"><i class="fa fa-google-plus"></i></a>
              </li>
              <li>
                <a href="javascript:;"><i class="fa fa-linkedin"></i></a>
              </li>
            </ul>
          </b-col>
          <b-col lg="7" sm="12" class="p-0 align-self-center">
            <div class="login-inner-form">
              <div class="details">
                <h3>欢迎注册博客账号</h3>
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  variant="success"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                >
                  <p>注册成功！{{ dismissCountDown }}秒后进行跳转到登录页。</p>
                  <b-progress
                    variant="success"
                    :max="dismissSecs"
                    :value="dismissCountDown"
                    height="3px"
                  ></b-progress>
                </b-alert>
                <b-form @submit.stop.prevent="onSubmit">
                  <b-form-group label-for="nested-nickname">
                    <b-form-input
                      id="nested-nickname"
                      v-model="userInfo.nickname"
                      placeholder="昵称"
                      name="nested-nickname"
                      v-validate="{ required: true, min: 1 }"
                      :state="validateState('nested-nickname')"
                      aria-describedby="input-nickname-feedback"
                      data-vv-as="个人昵称"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-nickname-feedback">{{
                      veeErrors.first("nested-nickname")
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group label-for="nested-username">
                    <b-form-input
                      id="nested-username"
                      v-model="userInfo.username"
                      placeholder="账号"
                      name="nested-username"
                      v-validate="{ required: true, min: 1 }"
                      :state="validateState('nested-username')"
                      aria-describedby="input-username-feedback"
                      data-vv-as="个人账号"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-username-feedback">{{
                      veeErrors.first("nested-username")
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group label-for="nested-password">
                    <b-form-input
                      id="nested-password"
                      v-model="userInfo.password"
                      placeholder="密码"
                      type="password"
                      name="nested-password"
                      v-validate="'required|min:1'"
                      :state="validateState('nested-password')"
                      ref="nested-password"
                      aria-describedby="input-password-feedback"
                      data-vv-as="个人密码"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-password-feedback">{{
                      veeErrors.first("nested-password")
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group label-for="nested-password2">
                    <b-form-input
                      id="nested-password2"
                      v-model="userInfo.password2"
                      placeholder="再次确定密码"
                      name="nested-password2"
                      type="password"
                      v-validate="'required|min:1|confirmed:nested-password'"
                      :state="validateState('nested-password2')"
                      aria-describedby="input-confirm_password-feedback"
                      data-vv-as="确定密码"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="input-confirm_password-feedback"
                      >{{
                        veeErrors.first("nested-password2")
                      }}</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group label-for="nested-email">
                    <b-form-input
                      id="nested-email"
                      v-model="userInfo.email"
                      placeholder="邮件地址"
                      name="nested-email"
                      type="email"
                      v-validate="{ required: true, min: 1, email: true }"
                      :state="validateState('nested-email')"
                      aria-describedby="input-email-feedback"
                      data-vv-as="邮件地址"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-email-feedback">{{
                      veeErrors.first("nested-email")
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                  <!-- <div class="form-group form-input form-checkbox">
                   <b-row >
                      <b-col class="pr-1" cols="4">
                        <a href="javascript:;" @click="toggleVerCode" class="h-100">
                          <b-img-lazy :src="verCode" class="h-75"></b-img-lazy
                        ></a>
                      </b-col>
                      <b-col class="pl-0" cols="8">
                        <b-form-input
                          v-model="userInfo.verifyCode"
                          class="form-input"
                          placeholder="验证码"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div> -->
                  <b-input-group>
                    <b-form-input
                      class="form-input-one"
                       v-model="userInfo.verifyCode"
                      placeholder="填写收到邮件后的验证码"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-overlay
                        :show="busy"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        class="d-inline-block"
                        @hidden="onHidden"
                      >
                        <b-button
                          class="form-input-button"
                          @click="getEmailCaptcha"
                          >获取验证码</b-button
                        ></b-overlay
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <div class="form-group">
                    <b-button
                      block
                      class="login-submit"
                      :disabled="registerDisabled"
                      @click="onSubmit"
                    >
                      注册
                    </b-button>
                  </div>
                </b-form>
                <p>
                  已有账号!
                  <b-link href="javascript:;" @click="onLogin">去登录</b-link>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>
<script>
import { get, post } from "@/utils";
export default {
  name: "Register",
  data() {
    return {
      identifyCodes: "1234567890",
      userInfo: {
        nickname: null,
        username: null,
        password: null,
        password2: null,
        email: null,
        verifyCode: null,
      },
      registerDisabled: false,
      verCode: null,
      current: null,
      usernameAnime: null,
      passwordAnime: null,
      submitAnime: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      registerSuccess: false,
      busy: false,
    };
  },
  methods: {
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    onSubmit() {
      this.registerDisabled = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.registerDisabled = false;
          return;
        }
        if (this.userInfo.password != this.userInfo.password2) {
          this.registerDisabled = false;
          return;
        }
        post("/user/register", this.userInfo).then((res) => {
          if (res.code === 200 && res.data) {
            this.showAlert();
            this.registerSuccess = true;
            // this.$router.replace({ path: "/login" });
          } else {
            this.registerDisabled = false;
            this.userInfo.verifyCode = null;
            this.$bvToast.toast(res.msg, {
              title: "系统提示",
              variant: "danger",
            });
          }
        });
      });
    },
    toggleVerCode() {
      get("/user/registerKaptcha").then((res) => {
        if (res.code === 200) {
          this.verCode = res.data.verImg;
        }
      });
    },
    onSubmitEmail() {
      if (this.userInfo.email) {
        post("/user/sendEmailVerifyCode", { email: this.userInfo.email }).then(
          (res) => {
            if (res.code === 200 && res.data) {
              this.$bvToast.toast("发送成功!", {
                title: "系统提示",
                variant: "success",
              });
            } else {
              this.$bvToast.toast(res.msg, {
                title: "系统提示",
                variant: "danger",
              });
            }
          }
        );
      } else {
        this.$bvToast.toast("请输入邮箱地址", {
          title: "系统提示",
          variant: "warning",
        });
      }
    },
    onLogin() {
      this.$router.replace({ path: "/login" });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    getEmailCaptcha() {
       this.busy = true
      post("/user/sendEmailVerifyCode", { email: this.userInfo.email }).then(
        (res) => {
           this.busy = false
          if (res.code === 200) {
            this.$bvToast.toast(res.msg, {
              title: "系统提示",
              variant: "success",
            });
          } else {
            this.$bvToast.toast(res.msg, {
              title: "系统提示",
              variant: "warning",
            });
          }
        }
      );
    },
  },
  watch: {
    dismissCountDown(newValue) {
      if (newValue === 0 && this.registerSuccess) {
        this.onLogin();
      }
    },
  },
  beforeMount() {
    this.toggleVerCode();
  },
};
</script>
<style scoped src="../css/Register.css"></style>
