<template>
<b-container :fluid="$store.state.isFullWidth">
    <b-row>
     <!--  <b-col lg="8">
          <left-about-me/>
      </b-col>
      <b-col lg="4">
            <profile/>
      </b-col> -->
      <b-col sm="12">
          <b-container fluid>
            <div class="rd-google-map">
      <div class="overlay rd-google-map__model" onclick="style.pointerEvents='none'"></div>
      <iframe class="rd-google-map__model" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387142.8404068987!2d-74.2581973675357!3d40.70583158554698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2zVGjDoG5oIHBo4buRIE5ldyBZb3JrLCBUaeG7g3UgYmFuZyBOZXcgWW9yaw!5e0!3m2!1svi!2s!4v1480613299202" allowfullscreen=""></iframe>
    </div>
          </b-container>    
      </b-col>    
      <b-col sm="12">
          <div class="fields3">
              <br>
              <div class="banner2">
              <div class="info1">
              <div class="txt1">CAR MARKETPLACE Gmbh</div>
                </div><br>

                <p>39., Autodealer str. Munich., Deutschland</p>
                <p><i class="fa fa-envelope" aria-hidden="true"></i> - support@yourdomain.com</p>
                <p><i class="fa fa-phone" aria-hidden="true"></i> - (049) 345 4567</p>
                <p><i class="fa fa-clock-o" aria-hidden="true"></i> - Monday to Friday from 09.00 to 18.00</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque varius consectetur tempor. Cras suscipit, mi ut ultrices pharetra, justo orci tempus augue, et lobortis nulla massa vel eros. Proin non lacus ex. Integer maximus malesuada tincidunt. Nunc pellentesque elit quis elit ultrices euismod. Vivamus fermentum laoreet leo. Praesent ut ex in arcu fermentum hendrerit a sit amet urna. Praesent fringilla augue tellus, in lobortis urna molestie ac. Mauris volutpat quam nec nibh bibendum ullamcorper. Vivamus eget imperdiet erat. Vivamus porta nisi mi.</p>
               <br>
                </div>
            </div>
      </b-col>
    </b-row>
</b-container>

  
</template>
<script>
export default {
    name:"About",
    components:{
    }
}
</script>
<style scoped src="../css/About.css">

</style>