<template>
    <footer class="footer">
			<!-- Copyright -->
			<div class="copyright">
				<b-container >
					<b-row>
						<b-col cols="12">
							<div class="copyright-content">
								<!-- Copyright Text -->
								<p>© Copyright <a href="https://mail.sina.com" target="_blank">liuxd1314@sina.com</a>. Design .&amp; <a href="http://beian.miit.gov.cn" target="_blank">网站备案号：京ICP备19054707号</a></p>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
			<!--/ End Copyright -->
		</footer>
</template>
<script>
export default {
    name:"Footer",
    data(){
	return {
		
	}
    },
    methods:{

    }
}
</script>
<style scoped src="../../css/layout/Footer.css">

</style>