<template>
  <div class="comments">
    <h3 class="comment-title">全部评论 ({{ total }})</h3>
    <b-overlay :show="show" rounded="sm">
      <template v-if="commentList != null && commentList.length > 0">
        <template v-for="item in commentList">
          <comment-two
            :commentObj="item"
            v-bind:key="item.gid"
            :levelType="1"
            :parentUserId="userInfo ? userInfo.userId: null"
            :articleUserId="articleUserId"
            :articleId="articleId"
          />
        </template>
        <b-button block variant="info" v-if="commentList.length >5" @click="onClickLoadingCommentData"
          >继续加载5条</b-button
        >
      </template>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">加载中...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import CommentTwo from "@/components/child/CommentTwo";
import Bus from "@/utils/EventBus";
import { get } from "@/utils";
export default {
  components: { CommentTwo },
  name: "BlogCommentTwo",
  props: {
    articleUserId: {
      type: Number,
      require: true,
    },
    articleId: {
      type: String,
      require: true,
    },
    userInfo: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      commentList: null,
      pageNo: 0,
      pageSize: 5,
      isExistData: true,
      total: 0,
    };
  },
  methods: {
    initCommentInfo() {
      this.show = true;
      get("/comment/listForArticle", {
        articleId: this.articleId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.show = false;
        if (
          res.code === 200 &&
          res.data &&
          res.data.content &&
          res.data.content.length > 0
        ) {
          this.total = res.data.totalElements;
          this.isExistData = !res.data.last;
          /*   if(this.commentList){
              this.commentList =this.commentList.concat(res.data.content)
            }else{
              this.commentList = res.data.content;
            } */
          this.commentList = res.data.content;
        }
      });
    },
    getCommentInfo(articleId) {
      this.show = true;
      get("/comment/listForArticle", {
        articleId: articleId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.show = false;
        if (
          res.code === 200 &&
          res.data &&
          res.data.content &&
          res.data.content.length > 0
        ) {
          this.total = res.data.totalElements;
          this.isExistData = !res.data.last;
          /*  if(this.commentList){
              this.commentList =this.commentList.concat(res.data.content)
            }else{
              this.commentList = res.data.content;
            } */
          this.commentList = res.data.content;
        }
      });
    },
    onClickLoadingCommentData() {
      if (this.isExistData) {
        // this.pageNo = this.pageNo + 1;
        this.pageSize = this.pageSize + 5;
        this.initCommentInfo();
      } else {
        this.$bvToast.toast("没有数据啦！", {
          title: "系统提示",
          variant: "success",
        });
      }
    },
  },
  mounted() {
    Bus.$on("getCommentInfo", this.getCommentInfo);
  },
  created() {
    this.initCommentInfo();
  },
  beforeDestroy() {
    Bus.$off("getCommentInfo", this.getCommentInfo);
  },
};
</script>
<style scoped>
.comments {
  margin-top: 40px;
}
.comments .comment-title {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 30px;
  display: block;
  background: #fff;
  padding-left: 12px;
}
.comments .comment-title:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  height: 100%;
  width: 3px;
  background: #f7941d;
}
</style>
