<template>
  <div
    :class="`elena-options ${sideFlag?`animated bounceOutLeft`:`animated fadeInUp`}`"
    :style="sideFlag ? `right: 0px;` : `right: -245px;`"
  >
    <div class="icon" @click="toggleSide">
      <i class="fa fa-cog fa-spin"></i>
    </div>
    <div class="single-option">
      <h4>选择布局</h4>
      <div class="select-layout">
        <a class="full-width" value="full-width" @click="initPageFullWidth(true)">全宽</a>
        <a class="boxed" value="boxed" @click="initPageFullWidth(false)">盒子</a>
      </div>
    </div>
    <div class="single-option">
      <h4>背景颜色</h4>
      <ul class="bg-pattern">
        <li class="bg-one">
          <a href="javascript:;" @click="toggleBgTemplateColor(1)">
            <b-img
              v-bind="mainProps"
              blank-color="#2e2751"
              alt="Named color image (red)"
            ></b-img>
          </a>
        </li>
        <li class="bg-two">
          <a href="javascript:;" @click="toggleBgTemplateColor(2)">
            <b-img
              v-bind="mainProps"
              blank-color="#777"
              alt="HEX shorthand color image (#777)"
            ></b-img>
          </a>
        </li>
        <li class="bg-three">
          <a href="javascript:;" @click="toggleBgTemplateColor(3)">
            <b-img
              v-bind="mainProps"
              blank-color="#4897d9"
              alt="Named color image (red)"
            ></b-img>
          </a>
        </li>
        <li class="bg-four">
          <a href="javascript:;" @click="toggleBgTemplateColor(4)">
            <b-img
              v-bind="mainProps"
              blank-color="#002E5B"
              alt="Named color image (red)"
            ></b-img>
          </a>
        </li>
        <li class="bg-five">
          <a href="javascript:;" @click="toggleBgTemplateColor(5)">
            <b-img
              v-bind="mainProps"
              blank-color="#072833"
              alt="Named color image (red)"
            ></b-img>
          </a>
        </li>
      </ul>
    </div>
    <div class="single-option">
      <h4>图标颜色</h4>
      <span class="skin1"></span>
      <span class="skin2"></span>
      <span class="skin3"></span>
      <span class="skin4"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "SideTools",
  components: {},
  data() {
    return {
      mainProps: { blank: true, width: 46, height: 36, class: "m1" },
      sideFlag: false,
    };
  },
  methods: {
    toggleSide() {
      this.sideFlag = !this.sideFlag;
    },
    toggleBgTemplateColor(value) {
      this.$store.state.template = `template-${value}`;
    },
    initPageFullWidth(flag){
      this.$store.state.isFullWidth = flag;
    }
  },
  mounted() {},
};
</script>
<style scoped src="../../css/layout/SideTools.css">
</style>