<template>
  <div class="single-f-news" v-if="newArticle"  v-b-tooltip.hover title="点击下面内容，进行跳转" >
    <div class="post-thumb">
      <a href="javascript:;">
        <b-avatar :text="newArticle.headPortraitUrl? null:``+(index + 1)" size="60px" class="avatar" :src="`${$store.state.imgUrl}/${newArticle.headPortraitUrl}`"></b-avatar>
      </a>
    </div>
    <div class="content">
      <p class="post-meta">
        <time class="post-date">
          <i class="fa fa-clock-o"></i>{{$moment(newArticle.createDate).format("YYYY年MM月DD日")}}
        </time>
      </p>
      <h4 class="title text-truncate">
        <router-link :to="`/detail/${newArticle.articleId}`">{{newArticle.content}}
        </router-link>
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleItem",
  props:{
    newArticle:{
      type:Object,
    },
    index:{
       type:Number
    }
   
  },
  data(){
    return {
      regex:/(<([^>]+)>)/ig
    }
  }
};
</script>
<style scoped>

.single-f-news {
    position: relative;
    background: transparent;
    margin-bottom: 20px;
    box-shadow: none;
}

.single-f-news .content {
    padding-left: 80px;
}

.single-f-news .post-meta {
    margin-top: 5px;
    font-size: 13px;
    margin-bottom: 0px;
}

.single-f-news .avatar {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 4px;
    /* padding: 5px; */
}

.single-f-news .title {
    font-size: 6px;
    font-weight: medium;
    line-height: 24px;
    font-weight: 600;
}

.single-f-news .post-meta i {
    color: #179E66;
    margin-right: 5px;
}

.single-f-news .title:hover a {
    color: #179E66;
}

.single-f-news .title a {
    color: #2e2751;
}
</style>