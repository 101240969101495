<template>
  <div class="post-nav" v-if="prevNextList">
    <div class="prev-post" v-if="prevNextList[0] && articleInfo && articleInfo.articleId != prevNextList[0].articleId ">
      <div class="post-thumb">
        <a href="javascript:;" @click="onClickToggle(prevNextList[0].articleId)">
          <b-img-lazy rounded="circle" alt="上一篇" :src="prevNextList[0].homeImgUrl" style="widht:90px;height:90px;"></b-img-lazy>
        </a>
      </div>
      <h3 class="post-title">
        <a
          href="javascript:;"
          rel="prev"
        >
          <span>上一篇</span> {{prevNextList[0].title}}
        </a>
      </h3>
    </div>
    <div class="next-post" v-if="prevNextList[1]  && articleInfo && articleInfo.articleId != prevNextList[1].articleId">
      <h3 class="post-title">
        <a
          href="javascript:;"
          rel="next"
        >
          <span>下一篇</span>{{prevNextList[1].title}}
        </a>
      </h3>
      <div class="post-thumb">
        <a href="javascript:;" @click="onClickToggle(prevNextList[1].articleId)">
          <b-img-lazy rounded="circle" alt="Circle image" :src="prevNextList[1].homeImgUrl"  style="widht:90px;height:90px;"></b-img-lazy>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/utils/EventBus";
export default {
  name: "PostsNav",
  props:{
    prevNextList:{
      type:Array
    },
    articleInfo:{
      tyep:Object,
    }
  },
  methods:{
    onClickToggle(articleId){
      Bus.$emit("refreshFulltext",articleId);
    }
  }
};
</script>
<style scoped src="../../css/child/PostsNav.css">

</style>