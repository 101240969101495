<template>
  <div class="topbar">
    <b-container>
      <b-row>
        <b-col class="contact-info">
          <!-- Top Contact -->
          <div class="top-contact">
            <!-- <div class="single-contact">
              <i class="fa fa-phone"></i>电话: +(600) 125-4985-214
            </div>-->
            <!-- <div class="single-contact contact-email">
              <i class="fa fa-envelope-open"></i>邮箱: liuxd1314@sina.com
            </div> -->
            <div class="single-contact">
              <i class="fa fa-clock-o"></i>
              当前日期: {{ $moment().format("YYYY年MM月DD日") }}
            </div>
          </div>
          <!-- End Top Contact -->
        </b-col>
        <b-col class="oper-info">
          <div class="topbar-right">
            <!-- Social Icons -->
            <!-- <ul class="social-icons">
              <li>
                <a href="javascript:;">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <i class="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <i class="fa fa-dribbble"></i>
                </a>
              </li>
            </ul> -->
            <template v-if="meUserInfo">
              <b-row>
               <!--  <b-col cols="6" class="message-tools">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <a href="javascript:;">
                        <i class="fa fa-bell" aria-hidden="true"></i
                      ></a>
                    </template>
                    <b-dropdown-item href="javascript:;">个人中心</b-dropdown-item>
                    <b-dropdown-item href="javascript:;">设置</b-dropdown-item>
                    <b-dropdown-item href="javascript:;">退出</b-dropdown-item>
                  </b-dropdown>
                </b-col> -->
                <b-col cols="12" >
                  <b-dropdown
                    class="topbar-right"
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <b-avatar
                      variant="info"
                        :src="`${$store.state.imgUrl}/${meUserInfo.headPortraitStr}`"
                      ></b-avatar>
                    </template>
                    <b-dropdown-item href="/personal"
                      >个人中心</b-dropdown-item
                    >
                    <b-dropdown-item href="javascript:;">设置</b-dropdown-item>
                    <b-dropdown-item href="javascript:;" @click="onLogout"
                      >退出</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-overlay :show="loginButtonShow" rounded="sm">
              <b-button
                variant="outline-primary"
                class="bg-login"
                @click="onLogin"
              >
                登录/注册
              </b-button>
              </b-overlay>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-center" centered no-fade hide-header hide-footer>
      <b-row>
        <b-col cols="12" md="5" class="m-auto login-title text-center">
          <strong>注册登录</strong>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12" md="6" class="m-auto">
          <b-img
            thumbnail
            fluid
            src="https://picsum.photos/250/250/?image=58"
            alt="Image 2"
          ></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="m-auto text-center">
          <span>微信扫码登录</span>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12" md="9" class="m-auto">
          <b-breadcrumb>
            <b-breadcrumb-item href="javascript:;">账号密码登录</b-breadcrumb-item>
            <b-breadcrumb-item href="javascript:;">手机验证码登录</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col cols="12" md="6" class="m-auto text-center">
          <div class="h5 m-auto">
            <b-button variant="primary" class="mr-2">
              <b-icon icon="tools"></b-icon>
            </b-button>
            <b-button variant="primary" class="mr-2">
              <b-icon icon="credit-card"></b-icon>
            </b-button>
            <b-button variant="primary" class="mr-2">
              <b-icon icon="search"></b-icon>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Bus from "@/utils/EventBus";
import { post, get } from "@/utils";
export default {
  name: "Header",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      name: "",
      nameState: null,
      submittedNames: [],
      loginStatus: false,
      meUserInfo: null,
      loginButtonShow: true,
    };
  },
  methods: {
    onLogin() {
      this.$router.replace({ path: "/login" });
    },
    initLoginStatus() {
      if (
        this.$cookies.isKey("liuxd-blog-login") &&
        this.$cookies.get("liuxd-blog-login")
      ) {
        this.getUserInfo();
        
      }
      this.loginButtonShow = false;
    },
    onLogout() {
      this.$bvModal
        .msgBoxOk("您确定要退出系统吗？", {
          title: "系统提示",
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2",
          bodyClass: "body-font",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.state.loading = true;
            post("/sys/logout").then((res) => {
              this.$store.state.loading = false;
              if (res.code === 200) {
                this.$cookies.remove("liuxd-blog-login");
                 Bus.$emit("initMenuList");
                this.loginStatus = false;
                this.$store.state.userInfo = null;
                this.meUserInfo = null;
              }
            });
          }
        });
    },
    getUserheadPortraitStr(){
      get("/user/headPortraitStr").then(res=>{
        if(res.code === 200){
          this.meUserInfo.headPortraitStr = res.data;
        }
      })
    },
    getUserInfo() {
      get("/user/info").then((res) => {
        if (res.code === 200) {
          this.meUserInfo = res.data;
          this.$store.state.userInfo = res.data;
        }
      });
    }
  },
  watch:{
    meUserInfo(newValue){
      if(newValue){
        this.getUserheadPortraitStr();
      }
    }
  },
  mounted(){
    this.initLoginStatus();
    Bus.$on("navUserInfo",this.getUserInfo);
  },
  beforeMount(){
  },
  beforeDestroy(){
    Bus.$off("navUserInfo",this.getUserInfo);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-login {
  height: auto;
  text-transform: initial;
  padding: 8px 15px;
  line-height: initial;
  background: #fff;
  font-size: 14px !important;
  color: #f3a712 !important;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  border-radius: inherit;
}
.bg-button-mimetic {
  /* border-radius: 50px; */
  background: #2e2751;
  box-shadow: 20px 20px 60px #272145, -20px -20px 60px #352d5d;
}
.bg-login:hover {
  background: transparent;
  border-color: #fff;
  color: #fff !important;
}

.top-contact {
  padding-top: 1rem;
}
.topbar {
  background-color: #2e2751;
  padding: 8px 0;
}
.topbar .single-contact,
.topbar .single-contact a {
  color: #ccc;
  font-weight: normal;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 14px;
}
.topbar .single-contact i {
  color: #f3a712;
  margin-right: 10px;
  font-size: 18px;
  position: relative;
  top: 2px;
}
.topbar .single-contact:last-child {
  margin-right: 0px;
}
.topbar .single-contact {
  display: inline-block;
  margin-right: 25px;
  color: #ccc;
  cursor: pointer;
}
@media (min-width: 991px) {
  .topbar-right {
    position: relative;
    float: right;
  }
}

.topbar .social-icons {
  float: left;
  margin-right: 20px;
  padding-top: 1rem;
}
.topbar-right .button {
  float: left;
  right: 0;
}
.topbar .social-icons li {
  display: inline-block;
  margin-right: 10px;
}
.topbar .social-icons li a {
  color: #ccc;
}
.topbar-right .button .bizwheel-btn {
  height: auto;
  text-transform: initial;
  padding: 8px 15px;
  line-height: initial;
  background: #fff;
  font-size: 14px;
  color: #f3a712;
  border: 1px solid transparent;
}
.login-title {
  font-size: 24px;
}
.topbar .btn-primary {
  color: #ccc !important;
}

.template-1 .topbar {
  background-color: #2e2751;
}
.template-2 .topbar {
  background-color: #777777;
}
.template-3 .topbar {
  background-color: #4897d9;
}
.template-4 .topbar {
  background-color: #002e5b;
}
.template-5 .topbar {
  background-color:#072833;
}
.topbar .social-icons li a:hover {
  color: #f3a712;
}
.topbar .message-tools {
  line-height: 3.2rem;
}
.topbar .message-tools a {
  color: #ccc;
}
@media only screen and (max-width: 576px) {
  /* .topbar {
    display: none;
  } */
  .contact-info {
    padding-right: 0px;
  }
  .contact-email {
    display: none !important;
  }
 
  .oper-info {
    padding-left: 0px;
  }
}
 .topbar-right {
    text-align: right;
  }
.body-font {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
}
</style>
