var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:`single-comment ${
      _vm.levelType === 1
        ? 'one-level'
        : _vm.levelType === 2
        ? 'two-level'
        : _vm.levelType === 3
        ? 'three-level'
        : 'three-level'
    }`},[_c('b-avatar',{staticClass:"avatar",attrs:{"variant":_vm.commentObj.avatar?'light':'primary',"text":!_vm.commentObj.avatar ? _vm.commentObj.author.substring(0, 1) : null,"src":_vm.commentObj.avatar?`${_vm.$store.state.imgUrl}/${_vm.commentObj.avatar}`:null}}),_c('div',{staticClass:"content"},[_c('h4',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(_vm.commentObj.author))]),(_vm.articleUserId == _vm.commentObj.userId)?_c('a',{staticClass:"ml-1 mr-2 commenter-badge staff",attrs:{"href":"javascript:;"}},[_vm._v("作者")]):_vm._e(),(_vm.levelType > 1)?[_vm._m(0),_c('a',{staticClass:"form-author",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(_vm.parentAuthor))])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.commentObj.time)+" ")])],2),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.showAllComment
            ? _vm.commentObj.content.length > 100
              ? _vm.commentObj.content.substring(0, 30) + `......`
              : _vm.commentObj.content
            : _vm.commentObj.content
        )}}),_c('div',{staticClass:"button"},[(
            (_vm.parentUserId && _vm.parentUserId != _vm.commentObj.userId) ||
              !_vm.parentUserId
          )?_c('a',{staticClass:"btn",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.clickReply(_vm.commentObj)}}},[_c('i',{staticClass:"fa fa-reply",attrs:{"aria-hidden":"true"}}),_vm._v("回复")]):_vm._e(),_c('a',{class:`btn ml-2 ${
            _vm.commentObj.operType && _vm.commentObj.operType === 1
              ? `active animated flipInY`
              : ``
          }`,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.onClickLike(_vm.commentObj.gid)}}},[_c('i',{staticClass:"fa fa-thumbs-up",attrs:{"aria-hidden":"true"}}),_vm._v("支持 ("+_vm._s(_vm.commentObj.like)+")")]),_c('a',{class:`btn ml-2 ${
            _vm.commentObj.operType && _vm.commentObj.operType === 2
              ? `active animated flipInY`
              : ``
          }`,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.onClickDespise(_vm.commentObj.gid)}}},[_c('i',{staticClass:"fa fa-thumbs-down",attrs:{"aria-hidden":"true"}}),_vm._v("反对 ("+_vm._s(_vm.commentObj.despise)+")")])])])],1),(_vm.commentObj.child)?_vm._l((_vm.commentObj.child),function(threeItem){return _c('comment-two',{key:threeItem.gid,attrs:{"commentObj":threeItem,"articleId":_vm.articleId,"parentUserId":_vm.parentUserId,"parentAuthor":_vm.commentObj.author,"leftWidth":_vm.levelType <= 3 ? _vm.levelType * 110 : 3 * 110,"levelType":_vm.levelType + 1}})}):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"ml-2 mr-2",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"fa fa-reply fa-flip-horizontal",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }