<template>
<!-- Blog Sidebar -->
<div class="blog-sidebar">
    <!-- Single Sidebar -->
    <div class="single-sidebar blog_search">
        <b-form class="searchform" @submit="searchArticleText">
            <input type="text" placeholder="搜索关键词.." v-model="searchText" />
            <button type="submit">
                <i class="fa fa-search"></i>
            </button>
        </b-form>
    </div>
    <!--/ End Single Sidebar -->
    <!-- News Sidebar -->
    <div class="single-sidebar bizwheel_latest_news_widget">
        <h2 class="sidebar-title">分类</h2>
        <template v-if="groupList">
          <tree-view :classIfyList="groupList"/>
        </template>
        <template v-else>
            <b-card>
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
        </template>
    </div>
    <!-- News Sidebar -->
    <div class="single-sidebar bizwheel_latest_news_widget">
        <h2 class="sidebar-title">最新评论</h2>
        <template v-if="newCommentList">
            <template v-for="(item,index) in newCommentList">
                <single-item :newArticle="item" :index="index" v-bind:key="index" />
            </template>
        </template>
        <template v-else>
            <b-card>
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
        </template>
    </div>

    <!--/ End Single Sidebar -->
    <!-- News Tags -->
    <div class="single-sidebar tagcloud">
        <h2 class="sidebar-title">热点标签</h2>
        <ul v-if="tagsList">
            <li v-for="(item,index) in tagsList" v-bind:key="index">
                <a href="javascript:;" @click="getTagsArticleList(item)">{{item.tagName}}</a>
            </li>
        </ul>

    </div>
    <!--/ End News Tags -->
    <!-- News Tags -->
    <div class="single-sidebar tagcloud">
        <h2 class="sidebar-title">友情链接</h2>
        <ul v-if="frinendlyLinkList">
            <li v-for="(item,index) in frinendlyLinkList" v-bind:key="index" v-b-tooltip.hover :title="item.websiteName">
                <a :href="item.linkAddress" target="_black" class="tag-list text-truncate">{{item.websiteName}}</a>
            </li>
        </ul>
        <b-button class="frinendly-link-button mt-2" block squared v-b-modal.modal-frinendly-link>申请</b-button>
    </div>
  
</div>
<!--/ End Blog Sidebar -->
</template>

<script>
import SingleItem from "./SingleItem";
import EventBus from "@/utils/EventBus";
import {  get } from "@/utils";
import TreeView from './TreeView.vue';
export default {
  name: "Sidebar",
  components: {
    SingleItem,
    TreeView,
  },
  data() {
    return {
      newCommentList: null,
      tagsList: null,
      searchText:null,
      groupList:null,
      frinendlyLinkList:null,
      articleTagId:null,
     
    };
  },
  methods: {
    searchArticleText(event){
      event.preventDefault()
      EventBus.$emit("initArticleData", this.searchText,null,null);
    },
    openFrinendlyLink(){
       this.$bvModal.msgBoxOk('申请链接信息', {
          title: '申请链接信息',
          centered: true
        })
          .then(value => {
            this.boxOne = value
          })
          .catch(err => {
            // An error occurred
            console.log(err)
          })
    },
    initCommentData() {
      get("/article/newCommentList").then(res=>{
        if (res.code == 200) {
        this.newCommentList = res.data;
      }
      });
      
    },
    initTagsList() {
      get("/article/tagsList").then(res=>{
        if (res.code == 200) {
          this.tagsList = res.data;
        }
      });
    },
    initFrinendlyLink(){
      get("/article/friendlyLink").then(res=>{
          if (res.code === 200) {
            this.frinendlyLinkList = res.data;
          }
      });
       
    },
    initClassifyList(){
      get("/article/friendlyLink").then(res=>{
          if (res.code === 200) {
            this.frinendlyLinkList = res.data;
          }
      });
       
    },
    initTreeClassifyList(){
      get('/classify/list').then(res=>{
        if(res.code == 200){
          this.groupList = res.data;
        }
      })
    },
    getTagsArticleList(item){
      this.articleTagId = item.articleTagId;
       EventBus.$emit("initArticleData", null,null,item.articleTagId);
    }
  },
  mounted(){
    this.initCommentData();
    this.initTagsList();
    this.initFrinendlyLink();
    this.initTreeClassifyList();
  },
};
</script>

<style scoped src="../../css/Sidebar.css">
</style>
